import styled from 'styled-components'

export const StyledUIMsg = styled.p`
  color: var(--dark-gray);
  font-weight: var(--bold);
  font-size: 1.2rem;
  text-align: center;
  margin: auto;
  width: 100%;
`
