import styled from 'styled-components'

import { FiLogOut } from 'react-icons/fi'
import { AiOutlineSetting } from 'react-icons/ai'

interface Props {
  text: string
  onClickHandler?: () => void
}

const HeaderButton = ({ text, onClickHandler }: Props) => {
  return (
    <StyledHeaderButton type='button' onClick={onClickHandler}>
      {text === 'Log out' ? (
        <FiLogOut className='logout-icon' />
      ) : (
        <AiOutlineSetting className='settings-icon' />
      )}
      <span>{text}</span>
    </StyledHeaderButton>
  )
}

export default HeaderButton

const StyledHeaderButton = styled.button`
  background-color: var(--primary-black);
  border: none;
  border-left: 1px solid var(--white);
  color: var(--white);
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
  font-weight: var(--bold);
  padding: 15px 40px;
  outline: none;
  transition: opacity 200ms ease-out;

  .settings-icon,
  .logout-icon {
    transform: scale(1.4) translateY(1.5px);
    margin-right: 12px;
  }

  &:focus,
  &:hover {
    opacity: 0.85;
  }
`
