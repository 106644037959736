import styled, { css } from 'styled-components'

interface StyledProps {
  login?: boolean
  news?: boolean
  sales?: boolean
  file?: boolean
  number?: boolean
  crossSelling?: boolean
  duration?: boolean
  error?: boolean
  loginError?: boolean
}

export const Form = styled.form`
  ${({ login, news, sales }: StyledProps) => {
    if (login) {
      return css`
        padding: 40px 32px 32px 32px;
        border: 1px solid var(--border-gray);
      `
    }

    if (news || sales) {
      return css`
        margin-top: 30px;
      `
    }
  }}
`

export const Label = styled.label`
  display: block;
  color: var(--primary-black);

  ${({ login, news, sales, file }: StyledProps) => {
    if (login) {
      return css`
        color: ${({ error }: StyledProps) => (error ? 'var(--delete)' : 'var(--primary-black)')};
        font-size: 1rem;
        font-weight: var(--bold);
        margin-bottom: 5px;
      `
    }

    if (news || sales) {
      return css`
        font-size: 1rem;
        width: 50px;
        color: ${({ error }: StyledProps) => (error ? 'var(--delete)' : 'var(--primary-black)')};
      `
    }

    if (file) {
      return css`
        cursor: pointer;
        padding: 7.5px 11px;
        transition: all 200ms ease-out;

        &:focus,
        &:hover {
          color: var(--white);
        }
      `
    }
  }};
`

export const Input = styled.input`
  border-radius: 4px;
  outline: none;
  font-family: inherit;
  font-size: 1rem;
  transition: all 200ms ease-out;
  border: ${({ error }: StyledProps) =>
    error ? '1px solid var(--delete)' : '1px solid var(--border-light-gray)'};

  &:focus,
  &:hover {
    border: ${({ error }: StyledProps) =>
      error ? '1px solid var(--delete)' : '1px solid var(--primary-black)'};
  }

  ${({ login, news, sales, number, crossSelling, duration, error }: StyledProps) => {
    if (login) {
      return css`
        width: 100%;
        padding: 10px;
        border: ${error ? '1px solid var(--delete)' : '1px solid var(--border-input-gray)'};
      `
    }

    if (news || sales) {
      return css`
        width: 338px;
        padding: 8px 11px;

        &::placeholder {
          font-size: 0.875rem;
        }
      `
    }

    if (number) {
      return css`
        width: 130px;
        padding: 6px 11px;
        &::placeholder {
          font-size: 0.875rem;
        }
      `
    }
    if (crossSelling) {
      return css`
        width: 70px;
        padding: 6px 11px;
        margin-right: 5px;
        &::placeholder {
          font-size: 0.875rem;
        }
      `
    }

    if (duration) {
      return css`
        width: 163px;
        padding: 6px 11px;
        color: #797979;
        text-transform: uppercase;
        font-size: 0.8125rem;
        &::placeholder {
          font-size: 0.875rem;
        }
      `
    }
  }}
`

export const TextArea = styled.textarea`
  border: ${({ error }: StyledProps) =>
    error ? '1px solid var(--delete)' : '1px solid var(--border-light-gray)'};
  border-radius: 4px;
  font-family: inherit;
  font-size: 1rem;
  padding: 8px 11px;
  outline: none;
  width: 550px;

  &:focus,
  &:hover {
    border: ${({ error }: StyledProps) =>
      error ? '1px solid var(--delete)' : '1px solid var(--primary-black)'};
  }

  &::placeholder {
    font-size: 0.875rem;
  }

  @media (min-width: 1300px) {
    width: 623px;
  }
`

export const InputFile = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`

// FORM MESSAGES

export const FileMsg = styled.p`
  margin-left: 10px;
  font-size: 0.75rem;
  color: ${({ error }: StyledProps) => (error ? 'var(--delete)' : 'var(--primary-black)')};
`

export const ErrorInputMsg = styled.p`
  color: var(--delete);
  font-size: 12px;
  margin-top: 5px;

  ${({ loginError, error }: StyledProps) => {
    if (loginError) {
      return css`
        margin: 0 0 10px 0;
        font-size: 0.9375rem;
      `
    }

    if (error) {
      return css`
        margin: 0 0 0 10px;
        display: inline;
      `
    }
  }}
`

export const ImagePreview = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`

// FORM WRAPPERS

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 25px;

  ${({ news, sales }: StyledProps) =>
    (news || sales) &&
    css`
      display: flex;
      gap: 122px;
    `}
`

export const FileWrapper = styled.div`
  background-color: var(--border-light-gray);
  max-width: 70px;
  font-size: 0.75rem;
  transition: all 200ms ease-out;
  border-radius: 4px;

  &:focus,
  &:hover {
    background-color: var(--primary-black);
  }
`

export const FileMsgWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const FormBottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FormButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`
export const ImagePreviewWrapper = styled.div`
  width: 220px;
  max-height: 140px;
  border-radius: 5px;
`
