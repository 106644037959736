import { useTable, usePagination, useGlobalFilter } from 'react-table'
import { useLocation } from 'react-router-dom'
import { IoChevronForwardOutline, IoChevronBackOutline } from 'react-icons/io5'
import Search from '../../Search/Search'
import * as Styled from './TableItems.styles'
import { PaginationTableTypes } from '../../../interfaces/table.interface'
import { useMemo } from 'react'

const PAGE_SIZE = 5

const textMap = {
  '/news': 'Article',
  '/orders': 'Order',
  '/products': 'Product',
  '/clients': 'Client',
  '/messages': 'Message',
  '/groups': 'Group',
} as const

interface Props extends PaginationTableTypes {
  articleError?: Error
  ordersError?: Error
  data: any
  columns: any
  showSearch?: boolean
  isPaginated?: boolean
  isPortalSearch?: boolean
  text?: string
}
const TableItems = ({
  data,
  columns,
  articleError,
  ordersError,
  showSearch = true,
  isPaginated = false,
  isPortalSearch = false,
  text,
  searchTerm,
  isFetching,
  onSearchHandler,
  nextPage: paginateNext,
  previousPage: paginatePrevious,
  page: paginatePage,
  count,
}: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: PAGE_SIZE },
    },
    useGlobalFilter,
    usePagination,
  )

  const { pageIndex, globalFilter } = state

  const { pathname } = useLocation()
  const textSingle = text ?? textMap[pathname as keyof typeof textMap]
  const textPlural = (text ?? textMap[pathname as keyof typeof textMap]) + 's'
  const noResultsMsg =
    pathname === '/news' ? 'No Articles Found' : pathname === '/orders' ? 'No Orders Found' : 'No Data Found'
  const errorMsg = pathname === '/news' ? articleError?.message : ordersError?.message
  const paginateTotalPages = Math.ceil(count! / PAGE_SIZE)
  const canPaginateNext = (paginatePage ?? 0) < paginateTotalPages
  const canPaginatePrevious = paginatePage !== 1

  return (
    <>
      {showSearch && pathname !== '/products' && (
        <Search
          filter={searchTerm ?? globalFilter}
          setFilter={onSearchHandler ?? setGlobalFilter}
          isPortalSearch={isPortalSearch}
          isPaginated={isPaginated}
        />
      )}
      <Styled.Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Styled.Th {...column.getHeaderProps()}>{column.render('Header')}</Styled.Th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  const isImageHeader = cell.column.Header === 'Image'
                  return (
                    <Styled.Td isImageHeader={isImageHeader} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </Styled.Td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Styled.Table>
      {rows.length === 0 && <Styled.NoResults>{!errorMsg ? noResultsMsg : errorMsg}</Styled.NoResults>}
      {rows.length !== 0 && (
        <Styled.PaginationWrapper>
          <Styled.PageInfo>
            {count ?? rows.length} {(count ?? rows.length) === 1 ? textSingle : textPlural}
          </Styled.PageInfo>
          <Styled.ButtonWrapper>
            <Styled.PageInfo>
              Page {paginatePage ?? pageIndex + 1} of {count ? paginateTotalPages : pageOptions.length}
            </Styled.PageInfo>
            <Styled.PaginationButton
              onClick={() => {
                if (paginatePrevious) {
                  paginatePrevious(canPaginatePrevious)
                  return
                }
                previousPage()
              }}
              disabled={paginatePrevious ? !canPaginatePrevious : !canPreviousPage}>
              <IoChevronBackOutline />
            </Styled.PaginationButton>
            <Styled.PaginationButton
              onClick={() => {
                if (paginateNext) {
                  paginateNext(canPaginateNext)
                  return
                }
                nextPage()
              }}
              disabled={paginatePage ? isFetching || !canPaginateNext : !canNextPage}>
              <IoChevronForwardOutline />
            </Styled.PaginationButton>
          </Styled.ButtonWrapper>
        </Styled.PaginationWrapper>
      )}
    </>
  )
}

export default TableItems
