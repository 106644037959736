import React, { useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import FormSpinner from '../UI/Spinners/FormSpinner'
import * as Styled from '../UI/StyledForm'
import { StyledButton } from '../UI/StyledButton'
import { StyledDivider } from '../UI/StyledDivider'
import * as yup from 'yup'
import { GroupFormTypes, GroupTypes } from '../../interfaces/group.interface'
import { useHistory } from 'react-router-dom'
import SelectClientsAndGroupsModal from '../Modal/SelectClientsAndGroupsModal'

interface Props {
  group?: GroupTypes
  onSubmit: (values: GroupFormTypes) => void
  isFormLoading: boolean
}

const GroupForm = ({ group, onSubmit, isFormLoading }: Props) => {
  const inputFocus = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const initialValues = {
    name: group ? group.name : '',
    description: group ? group.description : '',
  }

  const validationSchema = yup.object({
    name: yup.string().trim().required(' ').max(50, 'Name must be less than 50 characters'),
    description: yup.string().trim(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => inputFocus.current?.focus(), [])

  const submitText = group ? 'Update' : 'Create'

  return (
    <Styled.Form onSubmit={formik.handleSubmit} news>
      <Styled.InputWrapper news>
        <Styled.Label news htmlFor="name" error={!!(formik.touched.name && formik.errors.name)}>
          Name:
        </Styled.Label>
        <div>
          <Styled.Input
            news
            id="name"
            type="text"
            placeholder="Add title"
            error={!!(formik.touched.name && formik.errors.name)}
            ref={inputFocus}
            {...formik.getFieldProps('name')}
          />
          {formik.touched.name && formik.errors.name && (
            <Styled.ErrorInputMsg error>{formik.errors.name}</Styled.ErrorInputMsg>
          )}
        </div>
      </Styled.InputWrapper>
      <Styled.InputWrapper news>
        <Styled.Label news htmlFor="description" error={!!(formik.touched.description && formik.errors.description)}>
          Description:
        </Styled.Label>
        <Styled.TextArea
          id="description"
          placeholder="Add text"
          rows={10}
          error={!!(formik.touched.description && formik.errors.description)}
          {...formik.getFieldProps('description')}
        />
      </Styled.InputWrapper>
      <Styled.InputWrapper news>
        <Styled.Label news>Clients:</Styled.Label>
        <SelectClientsAndGroupsModal showGroups={false} />
      </Styled.InputWrapper>
      <StyledDivider />
      <Styled.FormBottomWrapper>
        <Styled.FormButtonWrapper style={{ marginLeft: 'auto' }}>
          <StyledButton draft type="button" style={{ width: '140px' }} onClick={() => history.goBack()}>
            Cancel
          </StyledButton>
          <StyledButton publish type="submit">
            {isFormLoading ? <FormSpinner /> : submitText}
          </StyledButton>
        </Styled.FormButtonWrapper>
      </Styled.FormBottomWrapper>
    </Styled.Form>
  )
}

export default GroupForm
