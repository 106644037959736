import { useContext, useEffect, useRef, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import jwt_decode from 'jwt-decode'
import styled from 'styled-components'
import * as yup from 'yup'
import FormSpinner from '../UI/Spinners/FormSpinner'
import { StyledButton } from '../UI/StyledButton'
import { StyledLogo } from '../UI/StyledLogo'
import * as Styled from '../UI/StyledForm'
import { DASHBOARD } from '../../constant/routes'
import { authContext } from '../../context/auth-context'
import { DecodedTokenTypes } from '../../interfaces/decodedToken.interface'

const LoginForm = () => {
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const [newError, setNewError] = useState<string | null>(null)
  const history = useHistory()
  const { token, saveToken } = useContext(authContext)

  const inputFocus = useRef<HTMLInputElement>(null)
  useEffect(() => inputFocus.current?.focus(), [])

  const initialValues = {
    vat: '',
    password: '',
  }

  const validationSchema = yup.object({
    vat: yup.string().required(),
    password: yup.string().required(),
  })

  const onSubmit = async (values: {}) => {
    setNewError(null)
    setIsFormLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/token/`, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const jwt = await response.json()
      const decodedJWT: DecodedTokenTypes = jwt_decode(jwt.access)

      if (!response.ok || decodedJWT?.cus_id) {
        throw new Error('Invalid Vat or Password')
      }

      if (response.ok) {
        setNewError(null)
        saveToken(jwt.access)
        setIsFormLoading(false)
        history.replace('/')
      }
    } catch (err) {
      if (err instanceof Error) {
        setNewError(err.message)
        setIsFormLoading(false)
      }
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  if (token) {
    return <Redirect to={DASHBOARD} />
  }

  return (
    <StyledFormWrapper>
      <StyledLogoWrapper>
        <StyledLogo>Babywalker</StyledLogo>
      </StyledLogoWrapper>
      <Styled.Form login onSubmit={formik.handleSubmit}>
        {newError && <Styled.ErrorInputMsg loginError>{newError}</Styled.ErrorInputMsg>}
        <Styled.InputWrapper>
          <Styled.Label login htmlFor='vat' error={formik.touched.vat && formik.errors.vat ? true : false}>
            Vat:
          </Styled.Label>
          <Styled.Input
            login
            id='vat'
            type='text'
            error={formik.touched.vat && formik.errors.vat ? true : false}
            ref={inputFocus}
            {...formik.getFieldProps('vat')}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <Styled.Label
            login
            htmlFor='password'
            error={formik.touched.password && formik.errors.password ? true : false}
          >
            Password:
          </Styled.Label>
          <Styled.Input
            login
            id='password'
            type='password'
            error={formik.touched.password && formik.errors.password ? true : false}
            {...formik.getFieldProps('password')}
          />
        </Styled.InputWrapper>
        <StyledButton login type='submit'>
          {isFormLoading ? <FormSpinner /> : 'Login'}
        </StyledButton>
      </Styled.Form>
    </StyledFormWrapper>
  )
}

export default LoginForm

export const StyledFormWrapper = styled.div`
  position: relative;
  height: fit-content;
  width: 405px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StyledLogoWrapper = styled.div`
  background-color: var(--primary-black);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 61px;
`
