import { IoMdAdd, IoMdRemove } from 'react-icons/io'

interface Props {
  isAdded: boolean
  onClick: () => void
}
const TableAddRemoveActions = ({ isAdded, onClick }: Props) => {
  return isAdded ? (
    <IoMdRemove color="#000" onClick={onClick} size={24} cursor="pointer" />
  ) : (
    <IoMdAdd color="#000" onClick={onClick} size={24} cursor="pointer" />
  )
}

export default TableAddRemoveActions
