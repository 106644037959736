import { useCallback, useEffect, useState } from 'react'

export const usePagination = () => {
  const [page, setPage] = useState<number>(1)
  const [searchTerm, setSearchTerm] = useState<string>('')

  useEffect(() => {
    if (searchTerm) {
      setPage(1)
    }
  }, [searchTerm])

  const previousPage = (hasPrevious: boolean) => {
    if (page <= 1 || !hasPrevious) return
    setPage(prev => prev - 1)
  }
  const nextPage = (hasNext: boolean) => {
    if (!hasNext) return
    setPage(prev => prev + 1)
  }

  const onSearchHandler = useCallback((value: string) => setSearchTerm(value), [])

  return { page, searchTerm, previousPage, nextPage, onSearchHandler }
}
