import Spinner from '../UI/Spinners/Spinner'
import { usePagination } from '../../hooks/usePagination'
import useGroupsList from '../../api/groups/useGroupsList'
import GroupsTable from '../GroupsTable/GroupsTable'

interface Props {
  isModalTable?: boolean
}

const GroupsLoader = ({ isModalTable = false }: Props) => {
  const { page, onSearchHandler, nextPage, previousPage, searchTerm } = usePagination()
  const { data: groups, isLoading, isFetching } = useGroupsList({ page, searchTerm })

  return isLoading ? (
    <Spinner product="product" />
  ) : (
    <GroupsTable
      groups={groups?.results}
      isModalTable={isModalTable}
      count={groups.count}
      onSearchHandler={onSearchHandler}
      nextPage={nextPage}
      previousPage={previousPage}
      page={page}
      searchTerm={searchTerm}
      isFetching={isFetching}
    />
  )
}

export default GroupsLoader
