import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import DashboardNewsItem from './DashboardNewsItem/DashboardNewsItem'
import Spinner from '../UI/Spinners/Spinner'
import { StyledButton } from '../UI/StyledButton'
import { StyledPageTitle } from '../UI/StyledPageTitle'
import { StyledUIMsg } from '../UI/StyledUIMsg'
import { IoMdAdd } from 'react-icons/io'
import { NEWS } from '../../constant/routes'
import { AllNewsProperties } from '../../interfaces/news.interface'

interface Props {
  articles: AllNewsProperties[]
  isArticlesLoading: boolean
  articlesError: Error
}

const DashboardNews = ({ articles = [], isArticlesLoading, articlesError }: Props) => {
  const history = useHistory()

  const displayItems = articles.map(article => (
    <DashboardNewsItem key={article.id} title={article.title} date={article.published} id={article.id} />
  ))

  return (
    <DashboardNewsContainer>
      <StyledTitleWrapper>
        <StyledPageTitle>Our News</StyledPageTitle>
        <StyledButton onClick={() => history.push(NEWS.add)} publish>
          <IoMdAdd className='add-icon' />
          Add new
        </StyledButton>
      </StyledTitleWrapper>
      <StyledDashboardItemsWrapper>
        {isArticlesLoading && <Spinner dashboard='center' />}
        {!isArticlesLoading && !articlesError && displayItems}
        {!isArticlesLoading && !articlesError && articles.length === 0 && (
          <StyledUIMsg>You haven't added any articles to your News list yet</StyledUIMsg>
        )}
        {!isArticlesLoading && articlesError && <StyledUIMsg>{articlesError.message}</StyledUIMsg>}
      </StyledDashboardItemsWrapper>
    </DashboardNewsContainer>
  )
}

export default DashboardNews

const DashboardNewsContainer = styled.div`
  width: 100%;
  height: 350px;
  background-color: var(--light-gray);
  padding: 30px 20px 30px 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  @media (min-width: 1350px) {
    width: 50%;
    height: 100%;
  }
`

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  button {
    margin-right: 10px;
  }
`

const StyledDashboardItemsWrapper = styled.div`
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  --ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
