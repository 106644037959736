import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { authContext } from '../../context/auth-context'
import { CustomerDetailsTypes } from '../../interfaces/clients.interface'
import { useParams } from 'react-router-dom'

const getCustomerDetails = async ({ token, id }: { token: string; id: string }) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth-system/customer/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return response.json()
}

const useCustomersDetails = () => {
  const { token } = useContext(authContext)
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, error } = useQuery({
    queryKey: ['customer', id],
    queryFn: () => getCustomerDetails({ token: token!, id }),
    enabled: !!id,
  })

  return { data: data as CustomerDetailsTypes, isLoading, error }
}

export default useCustomersDetails
