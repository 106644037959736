import styled from 'styled-components'

export const StyledDivider = styled.hr`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--light-gray);
  margin: 30px 0 20px 0;

  @media (min-width: 1600px) {
    margin: 50px 0 40px 0;
  }
`
