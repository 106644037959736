import useLocalStorage from 'use-local-storage'

interface Props<T> {
  tabsKey: string
  tabsSections: readonly T[]
}

const UseTabs = <T>({ tabsKey, tabsSections }: Props<T>) => {
  const [activeTab, setActiveTab] = useLocalStorage<T>(tabsKey, tabsSections[0])
  const handleSetActiveTab = (sectionIndex: number) => setActiveTab(tabsSections[sectionIndex])

  return { activeTab, handleSetActiveTab }
}

export default UseTabs
