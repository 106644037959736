import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import styled from 'styled-components'
import { DASHBOARD } from '../constant/routes'

const NotFound = () => {
  useEffect(() => {
    document.title = 'Babywalker | Page not found'
  }, [])
  return (
    <StyledNotFoundContainer>
      <StyledTitle>Page Not Found</StyledTitle>
      <StyledMessage>
        The page you were looking for could not be found. Return to{' '}
        <Link to={DASHBOARD}>Dashboard</Link>.
      </StyledMessage>
    </StyledNotFoundContainer>
  )
}

export default NotFound

const StyledNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--primary-black);
`

const StyledTitle = styled.h2`
  text-transform: uppercase;
  font-weight: var(--black);
  font-size: 1.875rem;
  margin: 100px 0 15px 0;
`

const StyledMessage = styled.p`
  font-size: 1.25rem;

  a {
    font-weight: var(--bold);
    color: var(--primary-black);
  }
`
