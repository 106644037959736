import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { authContext } from '../../context/auth-context'
import { Paginated } from '../../interfaces/paginated.interface'
import { GroupTypes } from '../../interfaces/group.interface'

const getGroups = async ({ token, page, searchTerm }: { token: string; page: number; searchTerm: string }) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/groups/user-group/?search=${searchTerm}&page=${page}&page_size=5`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )

  return response.json()
}

const useGroupsList = ({ page = 1, searchTerm = '' }: { page: number; searchTerm: string }) => {
  const { token } = useContext(authContext)
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: ['groups', page, searchTerm],
    queryFn: () => getGroups({ token: token!, page: page, searchTerm: searchTerm }),
    keepPreviousData: true,
  })

  return { data: data as Paginated<GroupTypes[]>, isLoading, error, isFetching }
}

export default useGroupsList
