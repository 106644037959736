import useSWR from 'swr'
import { useContext } from 'react'
import { authContext } from '../context/auth-context'
import { dateFormat } from '../helpers/dateFormat'
import { sortByDatetime } from '../helpers/sortByDatetime'
import { toast } from 'react-toastify'

const useFetch = (url: string) => {
  const { token, destroyToken } = useContext(authContext)

  const fetcher = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    const data = await response.json()

    if (url === 'news/article') {
      if (response.status === 401) {
        toast.error('Your session has expired. Please log in.')
        destroyToken()
      }
      if (!response.ok) throw new Error('Failed to load articles. Please try again.')

      sortByDatetime(data.results, 'published')

      const transformedData = data?.results.map((result: { published: string }) => ({
        ...result,
        published: dateFormat(result.published),
        status: 'published',
      }))

      return transformedData
    }

    if (url === 'sales/offer') {
      if (!response.ok) throw new Error('Failed to load sales. Please try again.')

      sortByDatetime(data.results, 'expiration_date')

      const transformedData = data.results.map((result: { expiration_date: string }) => ({
        ...result,
        expiration_date: dateFormat(result.expiration_date),
      }))
      return transformedData
    }

    if (url === 'shop/cross-selling-sock/1') {
      if (!response.ok) throw new Error('Failed to load cross selling. Please try again.')
      return data
    }

    if (url.endsWith('/get-details')) {
      if (!response.ok) throw new Error('Failed to load order. Please try again.')

      return data
    }
  }

  const { data, error } = useSWR(token ? url : null, fetcher)

  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
  }
}

export default useFetch
