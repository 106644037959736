import { toast } from 'react-toastify'
import { NEWS, SALES } from '../constant/routes'

interface Types {
  url: string
  token: string | null
  formData: FormData
  setIsFormLoading: (value: boolean) => void
  mutate: (value: string) => void
  destroyToken: () => void
  history: { push: (value: string) => void }
}

export const createResource = async ({
  url,
  token,
  formData,
  setIsFormLoading,
  mutate,
  destroyToken,
  history,
}: Types) => {
  const resource = url.startsWith('news/article') ? 'Article' : 'Sale'

  try {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/${url}/`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response.ok) {
      toast.success(`${resource} has been created.`)
      setIsFormLoading(false)
      mutate(url.startsWith('news/article') ? 'news/article' : 'sales/offer')
      history.push(url.startsWith('news/article') ? NEWS.main : SALES.main)
    }

    if (response.status === 401) {
      destroyToken()
      throw new Error('Your session has expired. Please log in.')
    }

    if (!response.ok) {
      throw new Error(`Failed to create ${resource}. Please try again.`)
    }
  } catch (err) {
    if (err instanceof Error) {
      toast.error(err.message)
    }
    setIsFormLoading(false)
  }
}
