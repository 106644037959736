import { useContext, useState } from 'react'
import * as Styled from '../UI/StyledForm'
import styled from 'styled-components'
import { StyledDivider } from '../UI/StyledDivider'
import { StyledButton } from '../UI/StyledButton'
import { useSWRConfig } from 'swr'
import { useHistory } from 'react-router-dom'
import { authContext } from '../../context/auth-context'
import FormSpinner from '../UI/Spinners/FormSpinner'
import { updateResource } from '../../helpers/updateResource'

interface Props {
  discount: number
}

const CrossSellingInput = ({ discount }: Props) => {
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const [value, setValue] = useState<number>(discount)
  const { token, destroyToken } = useContext(authContext)
  const { mutate } = useSWRConfig()
  const history = useHistory()

  const onSubmitDiscount = () => {
    setIsFormLoading(true)
    updateResource({
      url: 'shop/cross-selling-sock/1',
      method: 'PATCH',
      formData: JSON.stringify({ discount: value }),
      token,
      setIsFormLoading,
      mutate,
      destroyToken,
      history,
    })
  }

  return (
    <>
      <StyledInputWrapper>
        <Styled.Label sales htmlFor='discount'>
          Discount:
        </Styled.Label>
        <div>
          <Styled.Input
            crossSelling
            type='number'
            min={0}
            max={100}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(+e.target.value)}
          />
          %
        </div>
      </StyledInputWrapper>

      <StyledDivider />
      <Styled.FormBottomWrapper>
        <StyledButton publish type='submit' onClick={onSubmitDiscount}>
          {isFormLoading ? <FormSpinner /> : 'Save'}
        </StyledButton>
      </Styled.FormBottomWrapper>
    </>
  )
}

export default CrossSellingInput

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
`
