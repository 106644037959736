import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { authContext } from '../../context/auth-context'
import { useParams } from 'react-router-dom'
import { GroupDetailsTypes, GroupTypes } from '../../interfaces/group.interface'

const getGroupDetails = async ({ token, id }: { token: string; id: string }) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/groups/user-group/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return response.json()
}

const useGroupDetails = () => {
  const { token } = useContext(authContext)
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, error } = useQuery({
    queryKey: ['group', id],
    queryFn: () => getGroupDetails({ token: token!, id }),
    enabled: !!id,
  })

  return { data: data as GroupDetailsTypes, isLoading, error }
}

export default useGroupDetails
