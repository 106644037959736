import styled from 'styled-components'
import { StyledPageTitle } from '../UI/StyledPageTitle'
import Spinner from '../UI/Spinners/Spinner'
import { StyledUIMsg } from '../UI/StyledUIMsg'
import DashboardOrdersItems from './DashboardOrdersItems/DashboardOrdersItems'
import { OrderTypes } from '../../interfaces/order.interface'

interface Props {
  orders: OrderTypes[]
  ordersError: Error
  isOrdersLoading: boolean
}

const DashboardOrders = ({ orders = [], ordersError, isOrdersLoading }: Props) => {
  const displayOrders = orders.map(order => (
    <DashboardOrdersItems key={order.id} code={order.code} date={order.timestamp} price={order.price} />
  ))
  return (
    <DashboardOrdersContainer>
      <StyledPageTitle>Orders</StyledPageTitle>

      <StyledDashboardItemsWrapper>
        {isOrdersLoading && <Spinner dashboard='center' />}
        {!isOrdersLoading && !ordersError && displayOrders}
        {!isOrdersLoading && !ordersError && orders.length === 0 && (
          <StyledUIMsg>You don't have any orders yet</StyledUIMsg>
        )}
        {!isOrdersLoading && ordersError && <StyledUIMsg>{ordersError.message}</StyledUIMsg>}
      </StyledDashboardItemsWrapper>
    </DashboardOrdersContainer>
  )
}

export default DashboardOrders

const DashboardOrdersContainer = styled.div`
  width: 100%;
  height: 350px;
  background-color: var(--light-gray);
  padding: 40px 20px 30px 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  @media (min-width: 1350px) {
    width: 50%;
    height: 100%;
  }
`

const StyledDashboardItemsWrapper = styled.div`
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 29px;
  --ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
