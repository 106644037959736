import styled from 'styled-components'
import { motion } from 'framer-motion/dist/framer-motion'

interface Props {
  isActive: boolean
  label: string
  onClick: () => void
}

const tabVariants = {
  active: {
    borderBottom: '5px solid #000',
    color: '#000',
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
  inactive: {
    borderBottom: '5px solid #f3f3f3',
    color: '#C0C0C0',
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
}

const Tab = ({ isActive, label, onClick }: Props) => {
  return (
    <StyledTab variants={tabVariants} animate={isActive ? 'active' : 'inactive'} onClick={onClick} type="button">
      {label}
    </StyledTab>
  )
}

export default Tab

const StyledTab = styled(motion.button)`
  all: unset;
  border-bottom: 5px solid #f3f3f3;
  color: #c0c0c0;
  padding-bottom: 20px;
  transform: translateY(5px);
  cursor: pointer;
`
