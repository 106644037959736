import Spinner from '../UI/Spinners/Spinner'
import ClientsTable from '../ClientsTable/ClientsTable'
import useCustomersList from '../../api/customers/useCustomersList'
import { usePagination } from '../../hooks/usePagination'

interface Props {
  isModalTable?: boolean
}

const ClientsLoader = ({ isModalTable = false }: Props) => {
  const { page, onSearchHandler, nextPage, previousPage, searchTerm } = usePagination()
  const { data: customers, isLoading, isFetching } = useCustomersList({ page, searchTerm })

  return isLoading ? (
    <Spinner product="product" />
  ) : (
    <ClientsTable
      customers={customers?.results}
      isModalTable={isModalTable}
      count={customers.count}
      onSearchHandler={onSearchHandler}
      nextPage={nextPage}
      previousPage={previousPage}
      page={page}
      searchTerm={searchTerm}
      isFetching={isFetching}
    />
  )
}

export default ClientsLoader
