import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle } from '../../components/UI/StyledPageTitle'
import RenderIf from '../../components/UI/RenderIf'
import Spinner from '../../components/UI/Spinners/Spinner'
import useCustomersDetails from '../../api/customers/useCustomerDetails'
import styled from 'styled-components'
import { StyledOrderDetail as StyledClientDetail, StyledOrderText as StyledClientText } from '../Orders/OrderDetail'
import moment from 'moment/moment'
import { Fragment } from 'react'
import { StyledDivider } from '../../components/UI/StyledDivider'

const ClientDetails = () => {
  const { data: customer, isLoading } = useCustomersDetails()

  return (
    <StyledContainer orderDetailsContent>
      <StyledPageTitle>Client Details</StyledPageTitle>
      <RenderIf isTrue={isLoading}>
        <Spinner />
      </RenderIf>
      <RenderIf isTrue={!isLoading && !!customer}>
        <StyledClientDetailsWrapper>
          <StyledClientDetail>
            <StyledClientText>
              <span>Customer Id:</span>
              {customer?.customer_info?.cus_id}
            </StyledClientText>
            <StyledClientText>
              <span>Code:</span>
              {customer?.customer_info?.code}
            </StyledClientText>
            <StyledClientText>
              <span>Name:</span>
              {customer?.customer_info?.name}
            </StyledClientText>
            <StyledClientText>
              <span>Entry Date:</span>
              {moment(customer?.customer_info?.entry_date).format('DD.MM.YYYY')}
            </StyledClientText>
            <StyledClientText>
              <span>AFM:</span>
              {customer?.customer_info?.afm}
            </StyledClientText>
            <StyledClientText>
              <span>Email:</span>
              {customer?.customer_info?.email}
            </StyledClientText>
            <StyledClientText>
              <span>Occupation:</span>
              {customer?.customer_info?.occupation}
            </StyledClientText>
            <StyledClientText>
              <span>Payment method:</span>
              {customer?.customer_info?.payment_descr}
            </StyledClientText>
            <StyledClientText>
              <span>Currency:</span>
              {customer?.customer_info?.cur_descr}
            </StyledClientText>

            {customer?.customer_info.customer_branches.map((br, i) => (
              <Fragment key={i}>
                <StyledDivider />
                <StyledClientText>
                  <span>Branch Country:</span>
                  {br.bra_country_descr}
                </StyledClientText>
                <StyledClientText>
                  <span>Branch City:</span>
                  {br.bra_city}
                </StyledClientText>
                <StyledClientText>
                  <span>Branch Street:</span>
                  {br.bra_street}
                </StyledClientText>
                <StyledClientText>
                  <span>Zip Code:</span>
                  {br.bra_zipcode}
                </StyledClientText>
                <StyledClientText>
                  <span>Phone:</span>
                  {br.bra_phone1} {br.bra_phone2}
                </StyledClientText>
                <StyledClientText>
                  <span>FPA Status:</span>
                  {br.bra_fpa_status_descr}
                </StyledClientText>
                <StyledClientText>
                  <span>Payment method:</span>
                  {br.bra_payment_descr}
                </StyledClientText>
              </Fragment>
            ))}
          </StyledClientDetail>
        </StyledClientDetailsWrapper>
      </RenderIf>
    </StyledContainer>
  )
}

export default ClientDetails

const StyledClientDetailsWrapper = styled.div`
  margin-top: 40px;
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  gap: 40px;
`
