import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import GlobalStyles from './global-styles'
import AuthContextProvider from './context/auth-context'
import OrdersProvider from './context/orders-context'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import SelectClientsGroupsProvider from './context/select-clients-groups-context'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 20000,
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <OrdersProvider>
          <SelectClientsGroupsProvider>
            <Router>
              <GlobalStyles />
              <App />
            </Router>
          </SelectClientsGroupsProvider>
        </OrdersProvider>
      </QueryClientProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
