import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import TableItems from './TableItems/TableItems'
import { MdDelete, MdShoppingCart } from 'react-icons/md'
import { RiEdit2Fill } from 'react-icons/ri'
import { deleteResourceById } from '../../helpers/deleteResourceById'
import { authContext } from '../../context/auth-context'
import { NEWS, ORDERS, PRODUCTS } from '../../constant/routes'
import { AllNewsProperties } from '../../interfaces/news.interface'
import { OrderTypes } from '../../interfaces/order.interface'
import { StyledActionsWrapper, StyledImage, StyledLink, StyledSpan, StyledTableContainer } from './styles'

interface Props {
  articles?: AllNewsProperties[]
  orders?: OrderTypes[]
  products?: any
  articleError?: Error
  ordersError?: Error
}

interface OriginalIdType {
  cell: { row: { original: { id: string; mat_id: number; color_code: string } } }
}

const Table = ({ articles, orders, products, articleError }: Props) => {
  const { token, destroyToken } = useContext(authContext)
  const { mutate } = useSWRConfig()

  const columns = [
    {
      Header: `${products || orders ? 'Code' : 'Title'}`,
      accessor: `${products || orders ? 'code' : 'title'}`,
    },
    {
      Header: `${articles ? 'Date' : orders ? 'Customer' : 'Color'}`,
      accessor: `${articles ? 'published' : orders ? 'customer' : 'color_code'}`,
    },
    {
      Header: `${orders ? 'Datetime' : 'Image'}`,
      accessor: `${products ? 'photo_url' : orders ? 'datetime' : 'image'}`,
      Cell: ({ value }: { value: string }) => {
        return orders ? (
          <p>{value}</p>
        ) : !value && !orders ? (
          <p>No image found</p>
        ) : (
          <StyledLink href={value} target="_blank" image={value}>
            <StyledImage src={value} />
          </StyledLink>
        )
      },
    },
    {
      Header: `${articles ? 'Status' : orders ? 'Price' : 'Sex'}`,
      accessor: `${articles ? 'status' : orders ? 'price' : 'sex_descr'}`,
      Cell: ({ value }: { value: string }) => {
        return <StyledSpan articles={!!articles}>{articles ? value : orders ? `${value}€` : value || 'N/A'}</StyledSpan>
      },
    },
    {
      Header: 'Actions',
      Cell: ({
        cell: {
          row: { original },
        },
      }: OriginalIdType) => {
        if (products) {
          return (
            <StyledActionsWrapper>
              <Link to={`${PRODUCTS.edit}/${original.mat_id}-${original.color_code}`}>
                <RiEdit2Fill className="edit-icon" />
              </Link>
            </StyledActionsWrapper>
          )
        }

        if (articles) {
          const urlDelete = `news/article/${original.id}/`
          const urlEditLink = `${NEWS.edit}/${original.id}`
          return (
            <StyledActionsWrapper>
              <Link to={urlEditLink}>
                <RiEdit2Fill className="edit-icon" />
              </Link>
              <MdDelete
                onClick={() => deleteResourceById({ url: urlDelete, token, mutate, destroyToken })}
                className="delete-icon"
              />
            </StyledActionsWrapper>
          )
        }

        if (orders) {
          return (
            <StyledActionsWrapper>
              <Link to={`${ORDERS.show}/${original.id}`}>
                <MdShoppingCart className="edit-icon" />
              </Link>
            </StyledActionsWrapper>
          )
        }
      },
    },
  ]

  return (
    <StyledTableContainer>
      <TableItems data={articles || orders || products} columns={columns} articleError={articleError} />
    </StyledTableContainer>
  )
}

export default Table
