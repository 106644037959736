import Modal from './Modal'
import SelectClientsButton from '../UI/SelectClientsButton'
import useTabs from '../../hooks/useTabs'
import TabsController from '../Tabs/TabsController'
import ClientsLoader from '../ClientsLoader/ClientsLoader'
import styled from 'styled-components'
import GroupsLoader from '../GroupsLoader/GroupsLoader'
import { useEffect, useState } from 'react'
import SelectedClientsLoader from '../SelectedClientsLoader/SelectedClientsLoader'
import { useSelectClientsGroups } from '../../context/select-clients-groups-context'
import useGroupViewTemp from '../../api/groups/useGroupViewTemp'
import { usePagination } from '../../hooks/usePagination'

const tabsSections = ['Clients', 'Groups'] as const
type TabSection = (typeof tabsSections)[number]

interface Props {
  showGroups?: boolean
}

const SelectClientsAndGroupsModal = ({ showGroups = true }: Props) => {
  const [showSelectedClients, setShowSelectedClients] = useState(false)
  const { selectedClients, tempId } = useSelectClientsGroups()
  const tempPagination = usePagination()
  const { data: tempCustomers, isLoading: isTemplLoading } = useGroupViewTemp({
    page: tempPagination.page,
    searchTerm: tempPagination.searchTerm,
  })

  const { activeTab, handleSetActiveTab } = useTabs<TabSection>({
    tabsKey: 'table-modal-tabs',
    tabsSections,
  })

  const isOnlyClients = !showGroups
  const isClientsTab = showGroups && activeTab === 'Clients'
  const isGroupsTab = showGroups && activeTab === 'Groups'
  const title = showGroups ? 'Select Clients or Group of clients' : 'Select Clients'

  useEffect(() => {
    if (selectedClients.length === 0) setShowSelectedClients(false)
  }, [selectedClients])

  return (
    <Modal
      trigger={<SelectClientsButton />}
      style={{
        maxWidth: '1440px',
        width: '100%',
        maxHeight: isOnlyClients ? '60%' : '70%',
        height: '100%',
      }}>
      {() => (
        <>
          <StyledContainer>
            <div className="container">
              <h2>{title}</h2>
              <button onClick={() => setShowSelectedClients(prev => !prev)}>
                Selected Clients ({tempId ? (tempCustomers?.count ?? 0) : selectedClients.length})
              </button>
            </div>

            <div id="search-portal" style={{ height: '49.5px' }} />
          </StyledContainer>
          {showGroups && !showSelectedClients && (
            <TabsController activeTab={activeTab} tabsSections={tabsSections} handleSetActiveTab={handleSetActiveTab} />
          )}
          {showSelectedClients && (
            <SelectedClientsLoader
              tempPagination={tempPagination}
              isModalTable={true}
              tempCustomers={tempCustomers}
              isTempLoading={isTemplLoading}
            />
          )}
          {isOnlyClients && !showSelectedClients && <ClientsLoader isModalTable={true} />}
          {isClientsTab && !showSelectedClients && <ClientsLoader isModalTable={true} />}
          {isGroupsTab && !showSelectedClients && <GroupsLoader isModalTable={true} />}{' '}
        </>
      )}
    </Modal>
  )
}

export default SelectClientsAndGroupsModal

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;

  .container {
    display: flex;
    gap: 10px;
    align-items: baseline;
    button {
      all: unset;
      cursor: pointer;
      font-size: 14px;
      text-decoration: underline;
    }
  }
`
