import { useEffect } from 'react'
import styled from 'styled-components'
import useFetch from '../hooks/useFetch'
import DashboardNews from '../components/DashboardNews/DashboardNews'
import DashboardOrders from '../components/DashboardOrders/DashboardOrders'
import { StyledContainer } from '../components/UI/StyledContainer'
import { AllNewsProperties } from '../interfaces/news.interface'
import { OrdersDataTypes } from '../interfaces/orders.interface'
import { useOrdersContext } from '../context/orders-context'

interface DashboardProps {
  articles: AllNewsProperties[]
  isArticlesLoading: boolean
  articlesError: Error
}

const Dashboard = ({
  articles,

  isArticlesLoading,

  articlesError,
}: DashboardProps) => {
  const { orders, isOrdersLoading, ordersError } = useOrdersContext()

  useEffect(() => {
    document.title = 'Babywalker | Dashboard'
  }, [])

  return (
    <StyledContainer dashboard>
      <StyledTopWrapper>
        <DashboardNews
          articles={articles}
          isArticlesLoading={isArticlesLoading}
          articlesError={articlesError}
        />
        <DashboardOrders orders={orders} ordersError={ordersError} isOrdersLoading={isOrdersLoading} />
      </StyledTopWrapper>
    </StyledContainer>
  )
}

export default Dashboard

const StyledTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 1350px) {
    flex-direction: row;
    justify-content: space-between;
    min-height: 100%;
  }
`
