import Modal from './Modal'
import { MdClose, MdDelete, MdWarning } from 'react-icons/md'
import styled from 'styled-components'
import * as Styled from '../UI/StyledForm'
import { StyledButton } from '../UI/StyledButton'
import FormSpinner from '../UI/Spinners/FormSpinner'
import React from 'react'

interface Props {
  title: string
  description: string
  onConfirm: () => void
  isLoading?: boolean
}

const ConfirmationDeleteModal = ({ title, description, onConfirm, isLoading }: Props) => {
  return (
    <Modal
      disabled={isLoading}
      trigger={<MdDelete className="delete-icon" />}
      style={{
        padding: '30px',
        maxWidth: '500px',
        width: '100%',
      }}>
      {({ close }) => (
        <StyledContainer>
          <div className="title-container">
            <MdWarning className="warning-icon" size={22} />
            <h3>{title}</h3>
            <MdClose onClick={close} cursor="pointer" className="close-icon" />
          </div>
          <p className="description">{description}</p>
          <Styled.FormButtonWrapper style={{ margin: '0 auto' }}>
            <StyledButton draft type="button" style={{ width: '140px' }} onClick={close}>
              No, Cancel
            </StyledButton>
            <StyledButton
              publish
              type="button"
              onClick={() => {
                onConfirm()
              }}>
              {isLoading ? <FormSpinner /> : 'Yes, Delete'}
            </StyledButton>
          </Styled.FormButtonWrapper>
        </StyledContainer>
      )}
    </Modal>
  )
}

export default ConfirmationDeleteModal

const StyledContainer = styled.div`
  .title-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;

    .warning-icon {
      color: var(--delete);
      transform: scale(1.2);
    }

    .close-icon {
      margin-left: auto;
    }
  }

  .description {
    margin: 15px 0 40px 0;
  }
`
