import { useEffect } from 'react'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle, StyledPageTitleWrapper } from '../../components/UI/StyledPageTitle'
import CrossSellingForm from '../../components/CrossSellingForm/CrossSellingForm'
import useFetch from '../../hooks/useFetch'
import { CrossSellingDataTypes } from '../../interfaces/cross-selling.interface'
import Spinner from '../../components/UI/Spinners/Spinner'

const CrossSelling = () => {
  useEffect(() => {
    document.title = 'Babywalker | Cross Selling'
  }, [])

  const { data: crossSelling, isLoading: isCrossSellingLoading }: CrossSellingDataTypes =
    useFetch('shop/cross-selling-sock/1')

  return (
    <StyledContainer mainContent>
      <StyledPageTitleWrapper>
        <StyledPageTitle>Cross Selling</StyledPageTitle>
      </StyledPageTitleWrapper>
      {isCrossSellingLoading ? <Spinner /> : <CrossSellingForm discount={crossSelling?.discount ?? 0} />}
    </StyledContainer>
  )
}

export default CrossSelling
