import React, { useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import FormSpinner from '../UI/Spinners/FormSpinner'
import * as Styled from '../UI/StyledForm'
import { StyledButton } from '../UI/StyledButton'
import { StyledDivider } from '../UI/StyledDivider'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import { MessageFormTypes } from '../../interfaces/message.interface'
import SelectClientsAndGroupsModal from '../Modal/SelectClientsAndGroupsModal'

interface Props {
  onSubmit: (values: MessageFormTypes) => void
  isFormLoading: boolean
}

const MessageForm = ({ onSubmit, isFormLoading }: Props) => {
  const inputFocus = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const initialValues = {
    subject: '',
    text: '',
  }

  const validationSchema = yup.object({
    subject: yup.string().trim().required(' ').max(50, 'Subject must be less than 50 characters'),
    text: yup.string().trim().required(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => inputFocus.current?.focus(), [])

  return (
    <Styled.Form onSubmit={formik.handleSubmit} news>
      <Styled.InputWrapper news>
        <Styled.Label news htmlFor="subject" error={!!(formik.touched.subject && formik.errors.subject)}>
          Title:
        </Styled.Label>
        <div>
          <Styled.Input
            news
            id="subject"
            type="text"
            placeholder="Add title"
            error={!!(formik.touched.subject && formik.errors.subject)}
            ref={inputFocus}
            {...formik.getFieldProps('subject')}
          />
          {formik.touched.subject && formik.errors.subject && (
            <Styled.ErrorInputMsg error>{formik.errors.subject}</Styled.ErrorInputMsg>
          )}
        </div>
      </Styled.InputWrapper>
      <Styled.InputWrapper news>
        <Styled.Label news htmlFor="text" error={!!(formik.touched.text && formik.errors.text)}>
          Message:
        </Styled.Label>
        <Styled.TextArea
          id="text"
          placeholder="Add text"
          rows={10}
          error={!!(formik.touched.text && formik.errors.text)}
          {...formik.getFieldProps('text')}
        />
      </Styled.InputWrapper>
      <Styled.InputWrapper news>
        <Styled.Label news>Clients:</Styled.Label>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <p style={{ fontSize: '12px', fontWeight: 'bold' }}>All Clients or</p>
          <SelectClientsAndGroupsModal showGroups />
        </div>
      </Styled.InputWrapper>
      <StyledDivider />
      <Styled.FormBottomWrapper>
        <Styled.FormButtonWrapper style={{ marginLeft: 'auto' }}>
          <StyledButton draft type="button" style={{ width: '140px' }} onClick={() => history.goBack()}>
            Cancel
          </StyledButton>
          <StyledButton publish type="submit">
            {isFormLoading ? <FormSpinner /> : 'Send'}
          </StyledButton>
        </Styled.FormButtonWrapper>
      </Styled.FormBottomWrapper>
    </Styled.Form>
  )
}

export default MessageForm
