import React, { useContext, useMemo } from 'react'
import { StyledActionsWrapper, StyledTableContainer } from '../Table/styles'
import TableItems from '../Table/TableItems/TableItems'
import { PaginationTableTypes } from '../../interfaces/table.interface'
import moment from 'moment'
import { Message } from '../../interfaces/message.interface'
import ConfirmationDeleteModal from '../Modal/ConfirmationDeleteModal'
import useDeleteMessage from '../../api/messages/useDeleteMessage'
import { authContext } from '../../context/auth-context'

interface Props extends PaginationTableTypes {
  messages: Message[]
}

const MessagesTable = ({
  messages = [],
  onSearchHandler,
  nextPage,
  previousPage,
  page,
  count,
  searchTerm,
  isFetching,
}: Props) => {
  const { token } = useContext(authContext)
  const { mutate: deleteMessage, isLoading } = useDeleteMessage()
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Subject',
        accessor: 'subject',
      },
      {
        Header: 'Message',
        accessor: 'text',
      },
      {
        Header: 'Created Date',
        accessor: 'created_date',
        Cell: ({ value }: { value: string }) => {
          return moment(value).format('DD.MM.YYYY - HH:mm')
        },
      },
      {
        Header: 'Actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: Message } }
        }) => {
          return (
            <StyledActionsWrapper>
              <ConfirmationDeleteModal
                isLoading={isLoading}
                title="Delete Message"
                description="Are you sure you want to delete this message?"
                onConfirm={() => deleteMessage({ token: token!, id: original.id })}
              />
            </StyledActionsWrapper>
          )
        },
      },
    ],
    [deleteMessage, isLoading, token],
  )

  return (
    <StyledTableContainer>
      <TableItems
        data={messages}
        columns={columns}
        text="Message"
        count={count}
        onSearchHandler={onSearchHandler}
        nextPage={nextPage}
        previousPage={previousPage}
        page={page}
        searchTerm={searchTerm}
        isFetching={isFetching}
        isPaginated
      />
    </StyledTableContainer>
  )
}

export default MessagesTable
