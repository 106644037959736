import styled, { css } from 'styled-components'

interface StyledProps {
  login?: boolean
  publish?: boolean
  draft?: boolean
}

export const StyledButton = styled.button`
  border: none;
  border-radius: 4px;
  background-color: var(--primary-black);
  color: var(--white);
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 0.875rem;
  text-transform: uppercase;
  outline: none;
  transition: all 200ms ease-out;

  &:hover {
    opacity: 0.85;
    outline: none;
  }

  .add-icon {
    color: var(--white);
    font-size: 15px;
    transform: translate(-4px, 2px);
  }

  ${({ login, publish, draft }: StyledProps) => {
    if (login) {
      return css`
        margin: 0 auto;
        padding: 14px 40px;
      `
    }

    if (publish) {
      return css`
        width: 140px;
        padding: 18px 0;
        max-height: 53px;
      `
    }

    if (draft) {
      return css`
        background-color: transparent;
        border: 1px solid var(--primary-black);
        color: var(--primary-black);
        width: 178px;
        max-height: 53px;

        &:hover {
          background-color: var(--border-light-gray);
        }
      `
    }
  }}
`
