import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  :root {
    
  /* Colors */
  --white: #fff;
  --primary-black:#0a0611; 

  --black-text: #000;
 
  --light-gray: #f3f3f3;
  --dark-gray: #878787;
 
  --border-gray: #707070;
  --border-light-gray: #d9d9d9;
  --border-input-gray: #878787;

  --published: #448f2a;
  --draft: #dcb73c;
  --delete: #ff3434;

  --toastify-color-success: #448f2a;
  --toastify-color-error: #ff3434;


  /* Font weights */
  --regular: 400;
  --bold: 700;
  --black: 900;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Lato', sans-serif;
    background-color: var(--white);
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  input {
    background-color: var(--white);
  }

  #root {
    height: 100vh;
    display: grid;
  }
`
export default GlobalStyles
