import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { CustomerInfoId, CustomerTypes } from '../interfaces/clients.interface'
import { GroupTypes } from '../interfaces/group.interface'

interface Props {
  children: ReactNode
}

interface SelectClientsGroupsContextTypes {
  selectedClients: CustomerTypes[] | CustomerInfoId[]
  selectedGroups: GroupTypes[]
  tempId: number | null
  onSelectClient: (client: CustomerTypes | CustomerInfoId) => void
  onSelectGroup: (group: GroupTypes) => void
  onSetTempId: (id: number | null) => void
  resetSelectedClients: () => void
  resetSelectedGroups: () => void
  isSelectedClient: (client: CustomerTypes) => boolean
  isSelectedGroup: (client: GroupTypes) => boolean
}

const selectClientsGroupsContext = createContext<SelectClientsGroupsContextTypes>({
  selectedClients: [],
  selectedGroups: [],
  tempId: null,
  onSelectClient: () => {},
  onSelectGroup: () => {},
  onSetTempId: () => {},
  resetSelectedClients: () => {},
  resetSelectedGroups: () => {},
  isSelectedClient: () => false,
  isSelectedGroup: () => false,
})

export const useSelectClientsGroups = () => useContext(selectClientsGroupsContext)

const SelectClientsGroupsProvider = ({ children }: Props) => {
  const [selectedClients, setSelectedClients] = useState<CustomerTypes[] | CustomerInfoId[]>([])
  const [selectedGroups, setSelectedGroups] = useState<GroupTypes[]>([])
  const [tempId, setTempId] = useState<number | null>(null)

  const isSelectedClient = useCallback(
    (client: CustomerTypes | CustomerInfoId) =>
      selectedClients.some(c => c.customer_info?.cus_id === client.customer_info?.cus_id),
    [selectedClients],
  )

  const isSelectedGroup = useCallback(
    (group: GroupTypes) => selectedGroups.some(g => g.id === group.id),
    [selectedGroups],
  )

  const onSelectClient = useCallback(
    (client: CustomerTypes | CustomerInfoId) => {
      const existingClient = isSelectedClient(client)

      if (existingClient) {
        setSelectedClients(prevClients =>
          prevClients?.filter(c => c.customer_info?.cus_id !== client.customer_info?.cus_id),
        )
        return
      }

      setSelectedClients(prevClients => [...prevClients, client])
    },
    [isSelectedClient],
  )

  const onSelectGroup = useCallback(
    (group: GroupTypes) => {
      const existingGroup = isSelectedGroup(group)

      if (existingGroup) {
        setSelectedGroups(prevClients => prevClients?.filter(g => g.id !== group.id))
        return
      }

      setSelectedGroups(prevGroups => [...prevGroups, group])
    },
    [isSelectedGroup],
  )

  const onSetTempId = useCallback((id: number | null) => {
    setTempId(id)
  }, [])

  const resetSelectedClients = useCallback(() => {
    setSelectedClients([])
  }, [])

  const resetSelectedGroups = useCallback(() => {
    setSelectedGroups([])
  }, [])

  const value = useMemo(
    () => ({
      selectedClients,
      selectedGroups,
      tempId,
      onSelectClient,
      onSelectGroup,
      onSetTempId,
      resetSelectedGroups,
      resetSelectedClients,
      isSelectedClient,
      isSelectedGroup,
    }),
    [
      selectedClients,
      selectedGroups,
      tempId,
      onSelectClient,
      onSelectGroup,
      onSetTempId,
      resetSelectedGroups,
      resetSelectedClients,
      isSelectedClient,
      isSelectedGroup,
    ],
  )

  return <selectClientsGroupsContext.Provider value={value}>{children}</selectClientsGroupsContext.Provider>
}

export default SelectClientsGroupsProvider
