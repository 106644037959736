import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import HeaderButton from './HeaderButton/HeaderButton'
import HeaderPaths from './HeaderPaths/HeaderPaths'
import { authContext } from '../../context/auth-context'
import { toast } from 'react-toastify'

const Header = () => {
  const location = useLocation()
  const { destroyToken } = useContext(authContext)

  const logoutHandler = () => {
    destroyToken()
    toast.success('You have been successfully logged out.')
  }

  return (
    <StyledHeader>
      <StyledHeaderTopWrapper>
        <StyledHeaderTitle>Babywalker administration</StyledHeaderTitle>
        <div>
          {/* <HeaderButton text='Profile Settings' /> */}
          <HeaderButton onClickHandler={logoutHandler} text='Log out' />
        </div>
      </StyledHeaderTopWrapper>
      <StyledHeaderBottomWrapper>
        <HeaderPaths path={location.pathname} />
      </StyledHeaderBottomWrapper>
    </StyledHeader>
  )
}

export default Header

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledHeaderTopWrapper = styled.div`
  width: 100%;
  background-color: var(--primary-black);
  height: 50px;
  display: flex;
  justify-content: space-between;
`

const StyledHeaderBottomWrapper = styled(StyledHeaderTopWrapper)`
  background-color: var(--light-gray);
  padding: 16px 0 16px 20px;

  @media (min-width: 1300px) {
    padding: 16px 0 16px 30px;
  }
`

const StyledHeaderTitle = styled.h2`
  color: var(--white);
  font-size: 14px;
  font-weight: var(--regular);
  text-transform: uppercase;
  padding: 16px 0 16px 20px;

  @media (min-width: 1300px) {
    padding: 16px 0 16px 30px;
  }
`
