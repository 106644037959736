import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Table from '../../components/Table/Table'
import { StyledButton } from '../../components/UI/StyledButton'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle, StyledPageTitleWrapper } from '../../components/UI/StyledPageTitle'
import { PropsArticles } from '../../interfaces/news.interface'
import { IoMdAdd } from 'react-icons/io'
import Spinner from '../../components/UI/Spinners/Spinner'
import { NEWS } from '../../constant/routes'

const News = ({ articles = [], isLoading, articleError }: PropsArticles) => {
  const history = useHistory()

  useEffect(() => {
    document.title = 'Babywalker | News'
  }, [])

  return (
    <StyledContainer mainContent>
      <StyledPageTitleWrapper>
        <StyledPageTitle>News - Articles</StyledPageTitle>
        <StyledButton onClick={() => history.push(NEWS.add)} publish>
          <IoMdAdd className='add-icon' />
          Add new
        </StyledButton>
      </StyledPageTitleWrapper>
      {isLoading ? <Spinner /> : <Table articles={articles} articleError={articleError} />}
    </StyledContainer>
  )
}

export default News
