import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { authContext } from '../../context/auth-context'
import { Paginated } from '../../interfaces/paginated.interface'
import { CustomerTypes } from '../../interfaces/clients.interface'

const getCustomers = async ({ token, page, searchTerm }: { token: string; page: number; searchTerm: string }) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/auth-system/customer/?query=${searchTerm}&page=${page}&page_size=5`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )

  return response.json()
}

const useCustomersList = ({ page = 1, searchTerm = '' }: { page: number; searchTerm: string }) => {
  const { token } = useContext(authContext)
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: ['customers', page, searchTerm],
    queryFn: () => getCustomers({ token: token!, page: page, searchTerm: searchTerm }),
    keepPreviousData: true,
  })

  return { data: data as Paginated<CustomerTypes[]>, isLoading, error, isFetching }
}

export default useCustomersList
