import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

const deleteMessage = async ({ token, id }: { token: string; id: number }) => {
  await fetch(`${process.env.REACT_APP_API_HOST}/api/pms/messages/${id}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

const useDeleteMessage = () => {
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation({
    mutationFn: deleteMessage,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['messages'])
      toast.success('Message has been deleted successfully')
    },
    onError: () => {
      toast.error('Failed to delete message')
    },
  })

  return { mutate, isLoading }
}

export default useDeleteMessage
