import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import QRCode from 'react-qr-code'
import FormSpinner from '../../UI/Spinners/FormSpinner'
import * as Styled from '../../UI/StyledForm'
import { StyledButton } from '../../UI/StyledButton'
import { StyledDivider } from '../../UI/StyledDivider'
import { ProductsTypes } from '../../../interfaces/products.interface'
import * as yup from 'yup'

interface Props {
  product: ProductsTypes
  onSubmit: (values: { image: string }) => void
  isFormLoading: boolean
}

const ProductForm = ({ product, onSubmit, isFormLoading }: Props) => {
  const [imagePreview, setImagePreview] = useState<string>(product ? product.photo_url : '')
  const [prevImage, setPrevImage] = useState<string | File>('')

  const initialValues = {
    image: product ? product.photo_url : '',
  }

  const validationSchema = yup.object({
    image: yup.string().required(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(imagePreview)
    },
    [imagePreview]
  )

  return (
    <>
      <div style={{ marginTop: '30px' }}>
        <Styled.InputWrapper news>
          <Styled.Label news>Code:</Styled.Label>
          <p>{product?.code || 'N/A'}</p>
        </Styled.InputWrapper>
        <Styled.InputWrapper news>
          <Styled.Label news>Color:</Styled.Label>
          <p>{product?.color_code || 'N/A'}</p>
        </Styled.InputWrapper>
        <Styled.InputWrapper news>
          <Styled.Label news>Description:</Styled.Label>
          <p>{product?.description || 'N/A'}</p>
        </Styled.InputWrapper>
        <Styled.InputWrapper news>
          <Styled.Label news>QR:</Styled.Label>
          <QRCode value={product?.mat_id.toString() || ''} size={100} />
        </Styled.InputWrapper>
      </div>

      <Styled.Form onSubmit={formik.handleSubmit} news>
        <Styled.InputWrapper news>
          <Styled.Label
            news
            htmlFor='image'
            error={formik.touched.image && formik.errors.image ? true : false}
          >
            Image:
          </Styled.Label>
          <Styled.FileMsgWrapper>
            <Styled.FileWrapper>
              <Styled.Label file htmlFor='image'>
                Browse...
              </Styled.Label>
            </Styled.FileWrapper>
            {!imagePreview && (
              <Styled.FileMsg error={formik.touched.image && formik.errors.image ? true : false}>
                No image selected.
              </Styled.FileMsg>
            )}
          </Styled.FileMsgWrapper>
          <Styled.InputFile
            type='file'
            id='image'
            name='image'
            accept='image/*'
            onChange={event => {
              if (event.target.files !== null) {
                formik.setFieldValue(
                  'image',
                  event.target.files[0] === undefined ? prevImage : event.target.files[0]
                )

                if (event.target.files[0] !== undefined) {
                  setPrevImage(event.target.files[0])
                  setImagePreview(URL.createObjectURL(event.target.files[0]))
                }
              }
            }}
            onBlur={formik.handleBlur}
          />
        </Styled.InputWrapper>
        <StyledDivider />
        <Styled.FormBottomWrapper>
          <Styled.ImagePreviewWrapper>
            {imagePreview && <Styled.ImagePreview src={imagePreview} />}
          </Styled.ImagePreviewWrapper>
          <Styled.FormButtonWrapper>
            <StyledButton publish type='submit'>
              {isFormLoading ? <FormSpinner /> : 'Publish'}
            </StyledButton>
          </Styled.FormButtonWrapper>
        </Styled.FormBottomWrapper>
      </Styled.Form>
    </>
  )
}

export default ProductForm
