import moment from 'moment'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import RenderIf from '../../components/UI/RenderIf'
import Spinner from '../../components/UI/Spinners/Spinner'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle } from '../../components/UI/StyledPageTitle'
import { StyledUIMsg } from '../../components/UI/StyledUIMsg'
import { useOrdersContext } from '../../context/orders-context'
import useFetch from '../../hooks/useFetch'
import { AllOrdetDetailsTypes } from '../../interfaces/order.interface'
import OrderDetail, { StyledOrderDetail, StyledOrderText } from './OrderDetail'

const OrderDetails = () => {
  const { orders } = useOrdersContext()
  const params: { id: string } = useParams()
  const {
    data: order,
    error,
    isLoading,
  }: AllOrdetDetailsTypes = useFetch(`shop/order/${params.id}/get-details`)
  const selectedOrder = orders.find(order => order.id === +params.id)

  const displayOrdersInfo = order?.map(order => <OrderDetail key={order.id} order={order} />)

  return (
    <StyledContainer orderDetailsContent>
      <StyledPageTitle>Order Details</StyledPageTitle>
      <RenderIf isTrue={isLoading}>
        <Spinner />
      </RenderIf>
      <RenderIf isTrue={!isLoading && !error && order}>
        <StyledOrderDetailsWrapper>
          <StyledOrderDetail>
            <StyledOrderText>
              <span>Code:</span>
              {selectedOrder?.code}
            </StyledOrderText>
            <StyledOrderText>
              <span>Customer:</span>
              {selectedOrder?.customer}
            </StyledOrderText>
            <StyledOrderText>
              <span>Products:</span>
              {order?.length}
            </StyledOrderText>
            <StyledOrderText>
              <span>Price:</span>
              {selectedOrder?.price}€
            </StyledOrderText>
            <StyledOrderText>
              <span>Date:</span>
              {moment(selectedOrder?.timestamp).format('DD.MM.YYYY - HH:mm')}
            </StyledOrderText>
            <StyledOrderText>
              <span>Delivery Date:</span>
              {moment(selectedOrder?.desired_delivery_date).format('DD.MM.YYYY')}
            </StyledOrderText>
            <RenderIf isTrue={selectedOrder?.notes !== ''}>
              <StyledOrderText>
                <span>Notes:</span>
                {selectedOrder?.notes}
              </StyledOrderText>
            </RenderIf>
          </StyledOrderDetail>
          {displayOrdersInfo}
        </StyledOrderDetailsWrapper>
      </RenderIf>
      <RenderIf isTrue={!isLoading && !order && error !== undefined}>
        <StyledOrderDetailsWrapper>
          <StyledUIMsg>Failed to load order details</StyledUIMsg>
        </StyledOrderDetailsWrapper>
      </RenderIf>
    </StyledContainer>
  )
}

export default OrderDetails

const StyledOrderDetailsWrapper = styled.div`
  margin-top: 40px;
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  gap: 40px;
`
