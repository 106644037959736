import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { StyledButton } from '../../components/UI/StyledButton'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle, StyledPageTitleWrapper } from '../../components/UI/StyledPageTitle'
import { CLIENTS } from '../../constant/routes'
import TabsController from '../../components/Tabs/TabsController'
import useTabs from '../../hooks/useTabs'
import ClientsLoader from '../../components/ClientsLoader/ClientsLoader'
import GroupsLoader from '../../components/GroupsLoader/GroupsLoader'

const tabsSections = ['Clients', 'Groups'] as const
type TabSection = (typeof tabsSections)[number]

const Clients = () => {
  const history = useHistory()
  const { activeTab, handleSetActiveTab } = useTabs<TabSection>({
    tabsKey: 'clients-tabs',
    tabsSections,
  })

  useEffect(() => {
    document.title = 'Babywalker | Clients'
  }, [])

  return (
    <StyledContainer mainContent>
      <StyledPageTitleWrapper>
        <StyledPageTitle>Clients</StyledPageTitle>
        <StyledButton
          onClick={() => history.push(CLIENTS.addGroup)}
          draft
          style={{
            padding: '18px 0',
          }}>
          Create Group
        </StyledButton>
      </StyledPageTitleWrapper>
      <div id="search-portal" style={{ height: '49.5px', marginBottom: '30px', marginTop: '20px' }} />
      <TabsController activeTab={activeTab} tabsSections={tabsSections} handleSetActiveTab={handleSetActiveTab} />
      {activeTab === 'Clients' && <ClientsLoader />}
      {activeTab === 'Groups' && <GroupsLoader />}
    </StyledContainer>
  )
}

export default Clients
