import styled from 'styled-components'
import Tab from './Tab'
import { motion } from 'framer-motion/dist/framer-motion'

interface Props {
  activeTab: string
  handleSetActiveTab: (sectionIndex: number) => void
  tabsSections: readonly string[]
}

const TabsController = ({ activeTab, handleSetActiveTab, tabsSections }: Props) => {
  return (
    <StyledTabContainer>
      {tabsSections.map(tab => (
        <Tab
          key={tab}
          isActive={tab === activeTab}
          label={tab}
          onClick={() => handleSetActiveTab(tabsSections.indexOf(tab))}
        />
      ))}
    </StyledTabContainer>
  )
}

export default TabsController

const StyledTabContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  gap: 60px;
  border-bottom: 5px solid #f3f3f3;
`
