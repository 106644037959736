import styled from 'styled-components'
import { OrderDetailsTypes } from '../../interfaces/order.interface'

interface Props {
  order: OrderDetailsTypes
}

const OrderDetail = ({ order }: Props) => {
  return (
    <StyledOrderDetail>
      <StyledOrderText>
        <span>Code:</span>
        {order.product_info.code}
      </StyledOrderText>
      <StyledOrderText>
        <span>Category:</span>
        {order.product_info.category_descr}
      </StyledOrderText>
      <StyledOrderText>
        <span>Color:</span>
        {order.product_info.color_descr}
      </StyledOrderText>
      <StyledOrderText>
        <span>Size:</span>
        {order.product_info?.[`size_${order.atlantis_size_id}_descr`]}
      </StyledOrderText>
      <StyledOrderText>
        <span>Sex:</span>
        {order.product_info.sex_descr}
      </StyledOrderText>
      <StyledOrderText>
        <span>Description:</span>
        {order.product_info.description}
      </StyledOrderText>
      <StyledOrderText>
        <span>Price:</span>
        {order.product_info.whs_price}€
      </StyledOrderText>
      <StyledOrderText>
        <span>Quantity:</span>
        {order.quantity}
      </StyledOrderText>
      <StyledOrderText>
        <span>Image:</span>
        <img src={order.product_info.photo_url} alt='' />
      </StyledOrderText>
    </StyledOrderDetail>
  )
}

export default OrderDetail

export const StyledOrderDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 40px;
  border-bottom: 2px solid var(--light-gray);
  &:last-child {
    border-bottom: none;
  }
`

export const StyledOrderText = styled.p`
  display: flex;
  gap: 10px;
  span {
    min-width: 200px;
    max-width: 200px;
    width: 100%;
    font-weight: var(--bold);
  }

  img {
    width: 150px;
    object-fit: cover;
  }
`
