import React from 'react'
import styled from 'styled-components'
import { IoMdAdd } from 'react-icons/io'

interface Props {
  onClick?: () => void
}

const SelectClientsButton = ({ onClick }: Props) => {
  return (
    <StyleSelectClientsButton onClick={onClick}>
      <div className="icon-plus">
        <IoMdAdd size={20} />
      </div>
      <span className="select-clients-text">SELECT CLIENTS</span>
    </StyleSelectClientsButton>
  )
}

export default SelectClientsButton

const StyleSelectClientsButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;

  & > * {
    transition: all 200ms;
  }

  .icon-plus {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e3e6e3;
    width: 25px;
    height: 25px;
  }

  .select-clients-text {
    color: #878787;
    font-size: 14px;
  }

  &:hover {
    .select-clients-text {
      color: var(--primary-black);
    }
    .icon-plus {
      background-color: var(--primary-black);
      color: var(--white);
    }
  }
`
