import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

const createMessage = async ({
  token,
  data,
}: {
  token: string
  data: {
    subject: string
    text: string
    all_customers: boolean
    customers: number[]
    groups: number[]
  }
}) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/pms/messages/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return response.json()
}

const useCreateMessage = () => {
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation({
    mutationFn: createMessage,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['messages'])
      toast.success('Message has been sent successfully')
    },
    onError: () => {
      toast.error('Failed to send message')
    },
  })

  return { mutate, isLoading }
}

export default useCreateMessage
