import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

const deleteGroup = async ({ token, id }: { token: string; id: number }) => {
  await fetch(`${process.env.REACT_APP_API_HOST}/api/groups/user-group/${id}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

const useDeleteGroup = () => {
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation({
    mutationFn: deleteGroup,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['groups'])
      toast.success('Group has been deleted successfully')
    },
    onError: () => {
      toast.error('Failed to delete group')
    },
  })

  return { mutate, isLoading }
}

export default useDeleteGroup
