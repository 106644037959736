import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

const updateGroup = async ({
  token,
  id,
  data,
}: {
  token: string
  id: number
  data: {
    name: string
    description: string
    customers: number[]
  }
}) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/groups/user-group/${id}/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return response.json()
}

const useUpdateGroup = () => {
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()

  const { mutate, isLoading } = useMutation({
    mutationFn: updateGroup,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['group', id])
      toast.success('Group has been updated successfully')
    },
    onError: () => {
      toast.error('Failed to update group')
    },
  })

  return { mutate, isLoading }
}

export default useUpdateGroup
