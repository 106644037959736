import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle, StyledPageTitleWrapper } from '../../components/UI/StyledPageTitle'
import { useEffect } from 'react'
import Table from '../../components/Table/Table'
import Spinner from '../../components/UI/Spinners/Spinner'
import { useOrdersContext } from '../../context/orders-context'

const Orders = () => {
  const { orders, ordersError, isOrdersLoading } = useOrdersContext()

  useEffect(() => {
    document.title = 'Babywalker | Orders'
  }, [])

  return (
    <StyledContainer mainContent>
      <StyledPageTitleWrapper>
        <StyledPageTitle>Orders</StyledPageTitle>
      </StyledPageTitleWrapper>
      {isOrdersLoading ? <Spinner /> : <Table orders={orders} ordersError={ordersError} />}
    </StyledContainer>
  )
}

export default Orders
