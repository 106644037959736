import { StyledContainer } from '../../../components/UI/StyledContainer'
import { StyledPageTitle } from '../../../components/UI/StyledPageTitle'
import GroupForm from '../../../components/GroupForm/GroupForm'
import { useSelectClientsGroups } from '../../../context/select-clients-groups-context'
import { GroupFormTypes } from '../../../interfaces/group.interface'
import { toast } from 'react-toastify'
import { useContext, useEffect } from 'react'
import { authContext } from '../../../context/auth-context'
import { useHistory } from 'react-router-dom'
import useGroupDetails from '../../../api/groups/useGroupDetails'
import Spinner from '../../../components/UI/Spinners/Spinner'
import useUpdateGroup from '../../../api/groups/useUpdateGroup'
import useGroupInitiateTemp from '../../../api/groups/useGroupInitiateTemp'

const EditGroup = () => {
  const { selectedClients, resetSelectedClients, onSelectClient, onSetTempId } = useSelectClientsGroups()
  const { token } = useContext(authContext)
  const { data: group, isLoading: isGroupLoading } = useGroupDetails()

  const history = useHistory()
  const { mutate, isLoading } = useUpdateGroup()
  const { data: temp } = useGroupInitiateTemp()

  const onSubmit = (values: GroupFormTypes) => {
    if (selectedClients.length === 0) {
      toast.error('Clients are required')
      return
    }
    mutate(
      {
        token: token!,
        id: group.id,
        data: {
          name: values.name,
          description: values.description,
          customers: selectedClients.map(c => c.customer_info.cus_id),
        },
      },
      {
        onSuccess: () => {
          resetSelectedClients()
          history.goBack()
        },
      },
    )
  }

  useEffect(() => {
    if (group) {
      group.customers.forEach(c => onSelectClient({ customer_info: { cus_id: c.atlantis_cus_id } }))
    }
  }, [group])

  useEffect(() => {
    if (temp) {
      onSetTempId(temp.temp_update_id)
    }
  }, [temp, onSetTempId])

  useEffect(
    () => () => {
      resetSelectedClients()
      onSetTempId(null)
    },
    [resetSelectedClients, onSetTempId],
  )

  return (
    <StyledContainer formContent>
      <StyledPageTitle>Edit Group</StyledPageTitle>
      {isGroupLoading ? (
        <Spinner product="product" />
      ) : (
        <GroupForm onSubmit={onSubmit} isFormLoading={isLoading} group={group} />
      )}
    </StyledContainer>
  )
}

export default EditGroup
