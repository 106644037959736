import moment from 'moment'
import { createContext, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import { OrderTypes } from '../interfaces/order.interface'
import { authContext } from './auth-context'

interface Props {
  children: React.ReactNode
}

interface OrdersContextTypes {
  orders: OrderTypes[]
  isOrdersLoading: boolean
  ordersError: Error
}

const OrdersContext = createContext<OrdersContextTypes>({
  orders: [],
  isOrdersLoading: false,
  ordersError: Error('Something went wrong'),
})

export const useOrdersContext = () => useContext(OrdersContext)

const OrdersProvider = ({ children }: Props) => {
  const { token } = useContext(authContext)
  const [orders, setOrders] = useState<OrderTypes[]>([])

  const fetcher = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/shop/order/get-orders-by-date/`, {
      method: 'POST',
      body: JSON.stringify({
        date_from: moment().subtract(2, 'days').format('DD-MM-YYYY'),
        date_to: moment().format('DD-MM-YYYY'),
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    const data = response.json()

    return data
  }

  const { data, error: ordersError } = useSWR(token ? 'orders' : null, fetcher)
  const isOrdersLoading = !data && !ordersError

  useEffect(() => {
    if (data) {
      const tranformedData = data
        ?.map((order: OrderTypes) => ({
          ...order,
          datetime: moment(order.timestamp).format('DD.MM.YYYY HH:mm'),
        }))
        .sort((a: { timestamp: string }, b: { timestamp: string }) => b.timestamp.localeCompare(a.timestamp))
      setOrders(tranformedData)
    }
  }, [data])

  const ctx = {
    orders,
    ordersError,
    isOrdersLoading,
  }
  return <OrdersContext.Provider value={ctx}>{children}</OrdersContext.Provider>
}

export default OrdersProvider
