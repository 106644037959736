import { StyledContainer } from '../../../components/UI/StyledContainer'
import { StyledPageTitle } from '../../../components/UI/StyledPageTitle'
import MessageForm from '../../../components/MessageForm/MessageForm'
import { MessageFormTypes } from '../../../interfaces/message.interface'
import { useSelectClientsGroups } from '../../../context/select-clients-groups-context'
import { useContext, useEffect } from 'react'
import useCreateMessage from '../../../api/messages/useCreateMessage'
import { authContext } from '../../../context/auth-context'
import { useHistory } from 'react-router-dom'

const AddMessage = () => {
  const { token } = useContext(authContext)
  const history = useHistory()
  const { selectedClients, selectedGroups, resetSelectedClients, resetSelectedGroups } = useSelectClientsGroups()
  const { mutate: createMessage, isLoading } = useCreateMessage()
  const onSubmit = (values: MessageFormTypes) => {
    const allCustomers = selectedClients.length === 0 && selectedGroups.length === 0
    createMessage(
      {
        token: token!,
        data: {
          ...values,
          all_customers: allCustomers,
          customers: selectedClients.map(c => c.customer_info.cus_id),
          groups: selectedGroups.map(g => g.id),
        },
      },
      {
        onSuccess: () => {
          history.goBack()
        },
      },
    )
  }

  useEffect(
    () => () => {
      resetSelectedClients()
      resetSelectedGroups()
    },
    [resetSelectedClients, resetSelectedGroups],
  )

  return (
    <StyledContainer formContent>
      <StyledPageTitle>Write new Message</StyledPageTitle>
      <MessageForm onSubmit={onSubmit} isFormLoading={isLoading} />
    </StyledContainer>
  )
}

export default AddMessage
