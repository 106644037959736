import { useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import ProductForm from '../../../components/Products/ProductForm/ProductForm'
import { StyledContainer } from '../../../components/UI/StyledContainer'
import { StyledPageTitle } from '../../../components/UI/StyledPageTitle'
import { authContext } from '../../../context/auth-context'
import { PRODUCTS } from '../../../constant/routes'

interface Props {
  searchProducts: (value: string) => void
  products: any[]
}
interface ProductParams {
  mat_id: number
  color_code: string
}

const EditProduct = ({ products = [], searchProducts }: Props) => {
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const { token, destroyToken } = useContext(authContext)
  const history = useHistory()
  const params: { id: string } = useParams()
  const [mat_id, color_code] = params.id.split('-')

  const product = products.find(
    (item: ProductParams) => item.mat_id === +mat_id && item.color_code === color_code
  )

  const method = product?.photo_url ? 'PUT' : 'POST'
  const url = product?.photo_url
    ? `/api/shop/product-photos/${product.photo_id}/`
    : '/api/shop/product-photos/'

  const onSubmit = async (values: { image: string }) => {
    setIsFormLoading(true)

    const editProduct = new FormData()
    editProduct.append('atlantis_mat_id', product.mat_id)
    editProduct.append('atlantis_color_code', product.color_code)
    editProduct.append('product_photo', values.image)

    if (product.photo_url === values.image) {
      toast.warn('There are no changes')
      setIsFormLoading(false)
      return
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}${url}`, {
        method: method,
        body: editProduct,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.ok) {
        searchProducts(product?.code)
        setIsFormLoading(false)
        toast.success('Image has been updated')
        history.push(PRODUCTS.main)
      }

      if (response.status === 401) {
        destroyToken()
        throw new Error('Your session has expired. Please log in.')
      }

      if (!response.ok) {
        throw new Error('Failed to edit image. Please try again')
      }
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message)
      }
      setIsFormLoading(false)
    }
  }

  return (
    <StyledContainer formContent>
      <StyledPageTitle>Edit Product</StyledPageTitle>
      <ProductForm product={product} onSubmit={onSubmit} isFormLoading={isFormLoading} />
    </StyledContainer>
  )
}

export default EditProduct
