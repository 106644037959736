import { toast } from 'react-toastify'
import { NEWS, SALES } from '../constant/routes'

interface Types {
  url: string
  method: string
  token: string | null
  formData: any
  setIsFormLoading: (value: boolean) => void
  mutate: (value: string) => void
  destroyToken: () => void
  history: { push: (value: string) => void }
}

export const updateResource = async ({
  url,
  method,
  token,
  formData,
  setIsFormLoading,
  mutate,
  destroyToken,
  history,
}: Types) => {
  const resource = url.startsWith('news/article')
    ? 'Article'
    : url.startsWith('sales/offer')
    ? 'Sale'
    : 'Cross Selling'

  try {
    const headers: any =
      resource === 'Cross Selling'
        ? {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        : {
            Authorization: `Bearer ${token}`,
          }

    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/${url}/`, {
      method,
      body: formData,
      headers,
    })

    if (response.ok) {
      toast.success(`${resource} has been updated.`)
      setIsFormLoading(false)
      mutate(
        url.startsWith('news/article')
          ? 'news/article'
          : url.startsWith('sales/offer')
          ? 'sales/offer'
          : 'shop/cross-selling-sock/1'
      )
      if (!url.startsWith('shop/cross-selling-sock/1')) {
        history.push(url.startsWith('news/article') ? NEWS.main : SALES.main)
      }
    }

    if (response.status === 401) {
      destroyToken()
      throw new Error('Your session has expired. Please log in.')
    }

    if (!response.ok) {
      throw new Error(`Failed to update ${resource}. Please try again.`)
    }
  } catch (err) {
    if (err instanceof Error) {
      toast.error(err.message)
    }
    setIsFormLoading(false)
  }
}
