import { useState } from 'react'
import {
  useFloating,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  useId,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
} from '@floating-ui/react'
import styled from 'styled-components'

interface Props {
  trigger: JSX.Element
  style?: React.CSSProperties
  disabled?: boolean
  children: ({ close }: { close: () => void }) => React.ReactNode
}

const Modal = ({ trigger, style, children, disabled }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  })

  const click = useClick(context)
  const role = useRole(context)
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' })
  const close = () => setIsOpen(false)
  const { getReferenceProps, getFloatingProps } = useInteractions([click, role, dismiss])

  const headingId = useId()
  const descriptionId = useId()

  return (
    <>
      <button
        style={{
          all: 'unset',
        }}
        ref={refs.setReference}
        {...getReferenceProps()}
        disabled={disabled}
        type="button">
        {trigger}
      </button>
      <FloatingPortal>
        {isOpen && (
          <FloatingOverlay
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'grid',
              placeItems: 'center',
              padding: '10px',
            }}
            lockScroll>
            <FloatingFocusManager context={context}>
              <StyledModal
                ref={refs.setFloating}
                aria-labelledby={headingId}
                aria-describedby={descriptionId}
                {...getFloatingProps()}
                style={{
                  outline: 'none',
                  ...style,
                }}>
                {children({ close })}
              </StyledModal>
            </FloatingFocusManager>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </>
  )
}

export default Modal

const StyledModal = styled.div`
  background: #fff;
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`
