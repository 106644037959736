import Spinner from '../UI/Spinners/Spinner'
import ClientsTable from '../ClientsTable/ClientsTable'
import useCustomersList from '../../api/customers/useCustomersList'
import { usePagination } from '../../hooks/usePagination'
import { useParams } from 'react-router-dom'
import { useSelectClientsGroups } from '../../context/select-clients-groups-context'
import { CustomerTypes } from '../../interfaces/clients.interface'
import { PaginationTableTypes } from '../../interfaces/table.interface'
import { Paginated } from '../../interfaces/paginated.interface'

interface Props {
  isModalTable?: boolean
  tempCustomers: Paginated<CustomerTypes[]>
  isTempLoading?: boolean
  tempPagination: PaginationTableTypes
}

const SelectedClientsLoader = ({ isModalTable = false, tempPagination, isTempLoading, tempCustomers }: Props) => {
  const { id } = useParams<{ id?: string }>()
  const { selectedClients, tempId } = useSelectClientsGroups()

  if (tempId) {
    return isTempLoading ? (
      <Spinner />
    ) : (
      <ClientsTable
        customers={tempCustomers?.results}
        isModalTable={isModalTable}
        count={tempCustomers.count}
        onSearchHandler={tempPagination.onSearchHandler}
        nextPage={tempPagination.nextPage}
        previousPage={tempPagination.previousPage}
        page={tempPagination.page}
        searchTerm={tempPagination.searchTerm}
        isFetching={tempPagination.isFetching}
        isPortalSearch
        isPaginated
      />
    )
  }

  if (!id) {
    return (
      <ClientsTable
        customers={selectedClients as CustomerTypes[]}
        isModalTable={isModalTable}
        isPaginated={false}
        isPortalSearch
      />
    )
  }

  return null
}

export default SelectedClientsLoader
