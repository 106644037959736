import React from 'react'

interface Props {
  className?: string
}

const IconMessages = ({ className }: Props) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none">
      <path
        d="M21.6 0H2.4C1.08 0 0.012 1.125 0.012 2.5L0 17.5C0 18.875 1.08 20 2.4 20H21.6C22.92 20 24 18.875 24 17.5V2.5C24 1.125 22.92 0 21.6 0ZM21.6 17.5H2.4V5L12 11.25L21.6 5V17.5ZM12 8.75L2.4 2.5H21.6L12 8.75Z"
        fill="black"
      />
    </svg>
  )
}

export default IconMessages
