import { useEffect } from 'react'
import SearchProducts from '../../components/Products/SearchProducts/SearchProducts'
import Table from '../../components/Table/Table'
import Spinner from '../../components/UI/Spinners/Spinner'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle } from '../../components/UI/StyledPageTitle'
import { ProductsTypes } from '../../interfaces/products.interface'

interface Props {
  products: ProductsTypes[]
  isProductsLoading: boolean
  searchProducts: (value: string) => void
}
const Products = ({ products = [], isProductsLoading, searchProducts }: Props) => {
  useEffect(() => {
    document.title = 'Babywalker | Products'
  }, [])

  return (
    <StyledContainer mainContent>
      <StyledPageTitle>Products</StyledPageTitle>
      <SearchProducts searchProducts={searchProducts} />
      {isProductsLoading ? <Spinner product='product' /> : <Table products={products} />}
    </StyledContainer>
  )
}

export default Products
