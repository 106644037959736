import { usePagination } from '../../hooks/usePagination'
import useMessagesList from '../../api/messages/useMessagesList'
import MessagesTable from '../MessagesTable/MessagesTable'
import Spinner from '../UI/Spinners/Spinner'

const MessagesLoader = () => {
  const { page, onSearchHandler, nextPage, previousPage, searchTerm } = usePagination()
  const { data: messages, isLoading, isFetching } = useMessagesList({ page, searchTerm })

  return isLoading ? (
    <Spinner product="product" />
  ) : (
    <MessagesTable
      messages={messages.results}
      count={messages.count}
      onSearchHandler={onSearchHandler}
      nextPage={nextPage}
      previousPage={previousPage}
      page={page}
      searchTerm={searchTerm}
      isFetching={isFetching}
    />
  )
}

export default MessagesLoader
