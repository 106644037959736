import styled from 'styled-components'
import { FiChevronRight } from 'react-icons/fi'

interface Props {
  path: string
}

const HeaderPaths = ({ path }: Props) => {
  let displayPath: string | JSX.Element

  const editResource = path.startsWith('/news/edit-article')
    ? '/news/edit-article'
    : path.startsWith('/orders/order')
      ? '/orders/order'
      : path.startsWith('/products/edit-product')
        ? '/products/edit-product'
        : path.startsWith('/clients/details')
          ? '/clients/details'
          : path.startsWith('/clients/edit-group')
            ? '/clients/edit-group'
            : ''

  switch (true) {
    case path === '/':
      displayPath = 'Dashboard'
      break
    case path === '/clients':
      displayPath = 'Clients'
      break
    case path === '/messages':
      displayPath = 'Messages'
      break
    case path === '/news':
      displayPath = 'News'
      break
    case path === '/orders':
      displayPath = 'Orders'
      break
    case path === '/products':
      displayPath = 'Products'
      break
    case path === '/cross-selling':
      displayPath = 'Cross Selling'
      break
    case path === '/news/add-article':
      displayPath = (
        <span>
          News <FiChevronRight className="chevron-icon" /> <span className="regular">Add Article</span>
        </span>
      )
      break
    case path === '/clients/create-group':
      displayPath = (
        <span>
          Clients <FiChevronRight className="chevron-icon" /> <span className="regular">Create Group</span>
        </span>
      )
      break
    case path === '/messages/add-message':
      displayPath = (
        <span>
          Messages <FiChevronRight className="chevron-icon" /> <span className="regular">Write New</span>
        </span>
      )
      break
    case path === '/sales/add-sale':
      displayPath = (
        <span>
          Sales <FiChevronRight className="chevron-icon" /> <span className="regular">Add new sale</span>
        </span>
      )
      break
    case editResource === '/clients/details':
      displayPath = (
        <span>
          Client <FiChevronRight className="chevron-icon" /> <span className="regular">Details</span>
        </span>
      )
      break
    case editResource === '/clients/edit-group':
      displayPath = (
        <span>
          Clients <FiChevronRight className="chevron-icon" /> <span className="regular">Edit Group</span>
        </span>
      )
      break
    case editResource === '/news/edit-article':
      displayPath = (
        <span>
          News <FiChevronRight className="chevron-icon" /> <span className="regular">Edit article</span>
        </span>
      )
      break
    case editResource === '/orders/order':
      displayPath = (
        <span>
          Order <FiChevronRight className="chevron-icon" /> <span className="regular">Details</span>
        </span>
      )
      break
    case editResource === '/products/edit-product':
      displayPath = (
        <span>
          Products <FiChevronRight className="chevron-icon" /> <span className="regular">Edit Product</span>
        </span>
      )
      break
    default:
      displayPath = ''
      break
  }
  return <StyledPaths>{displayPath}</StyledPaths>
}

export default HeaderPaths

const StyledPaths = styled.p`
  font-size: 1rem;
  font-weight: var(--bold);
  text-transform: capitalize;

  .chevron-icon {
    transform: scale(1.3) translateY(2.3px);
    margin: 0 6px;
  }

  .regular {
    font-weight: var(--regular);
  }
`
