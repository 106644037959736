import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

const createGroup = async ({
  token,
  data,
}: {
  token: string
  data: {
    name: string
    description: string
    customers: number[]
  }
}) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/groups/user-group/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return response.json()
}

const useCreateGroup = () => {
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation({
    mutationFn: createGroup,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['groups'])
      toast.success('Group has been created successfully')
    },
    onError: () => {
      toast.error('Failed to create group')
    },
  })

  return { mutate, isLoading }
}

export default useCreateGroup
