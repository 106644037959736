import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { StyledButton } from '../../components/UI/StyledButton'
import { StyledContainer } from '../../components/UI/StyledContainer'
import { StyledPageTitle, StyledPageTitleWrapper } from '../../components/UI/StyledPageTitle'
import { MESSAGES } from '../../constant/routes'
import MessagesLoader from '../../components/MessagesLoader/MessagesLoader'

const Messages = () => {
  const history = useHistory()

  useEffect(() => {
    document.title = 'Babywalker | Messages'
  }, [])

  return (
    <StyledContainer mainContent>
      <StyledPageTitleWrapper>
        <StyledPageTitle>Message</StyledPageTitle>
        <StyledButton
          onClick={() => history.push(MESSAGES.add)}
          publish
          style={{
            padding: '18px 0',
          }}>
          + Write New
        </StyledButton>
      </StyledPageTitleWrapper>
      <div id="search-portal" style={{ height: '49.5px', marginBottom: '30px', marginTop: '20px' }} />
      <MessagesLoader />
    </StyledContainer>
  )
}

export default Messages
