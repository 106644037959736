import styled from 'styled-components'
import { StyledLogo } from '../UI/StyledLogo'
import NavLink from './NavLink/NavLink'
import { RiDashboardFill } from 'react-icons/ri'
import { FaThList } from 'react-icons/fa'
import { IoMdCart } from 'react-icons/io'
import newsIcon from '../../assets/megaphone.svg'
import crossSellingIcon from '../../assets/cross-selling.png'
import { CLIENTS, CROSS_SELLING, DASHBOARD, MESSAGES, NEWS, ORDERS, PRODUCTS } from '../../constant/routes'
import IconUsers from '../UI/Icons/IconUsers'
import IconMessages from '../UI/Icons/IconMessages'

const Navigation = () => {
  return (
    <StyledNavigationWrapper>
      <StyledLogoWrapper>
        <StyledLogo>Babywalker</StyledLogo>
      </StyledLogoWrapper>
      <nav>
        <ul>
          <NavLink path={DASHBOARD} isExact>
            <RiDashboardFill className="dashboard-icon" />
            <span>Dashboard</span>
          </NavLink>
          <NavLink path={CLIENTS.main}>
            <IconUsers className="clients" />
            <span>Clients</span>
          </NavLink>
          <NavLink path={MESSAGES.main}>
            <IconMessages className="messages" />
            <span>Messages</span>
          </NavLink>
          <NavLink path={NEWS.main}>
            <img className="news-icon" src={newsIcon} alt="news icon" />
            <span>News</span>
          </NavLink>
          <NavLink path={ORDERS.main}>
            <IoMdCart size={25} className="orders-icon" />
            <span>Orders</span>
          </NavLink>
          <NavLink path={PRODUCTS.main}>
            <FaThList className="product-icon" />
            <span>Products</span>
          </NavLink>
          <NavLink path={CROSS_SELLING.main}>
            <img className="news-icon" src={crossSellingIcon} alt="news icon" width={23} />
            <span>Cross Selling</span>
          </NavLink>
        </ul>
      </nav>
    </StyledNavigationWrapper>
  )
}

export default Navigation

const StyledNavigationWrapper = styled.div`
  min-width: 260px;
  min-height: 100%;
  border-right: 1px solid var(--border-light-gray);
  padding: 32px 30px 30px 30px;

  @media (min-width: 1300px) {
    min-width: 330px;
    padding: 33px 38px 38px 38px;
  }
`

const StyledLogoWrapper = styled.div`
  border-bottom: 1px solid var(--border-light-gray);
  text-align: center;
  padding-bottom: 34px;
  margin-bottom: 20px;

  @media (min-width: 1300px) {
    margin-bottom: 35px;
  }
`
