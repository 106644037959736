import React, { useContext, useMemo } from 'react'
import { StyledActionsWrapper, StyledSpan, StyledTableContainer } from '../Table/styles'
import TableItems from '../Table/TableItems/TableItems'
import TableAddRemoveActions from '../Table/TableAddRemoveActions'
import { useSelectClientsGroups } from '../../context/select-clients-groups-context'
import { Link } from 'react-router-dom'
import { PaginationTableTypes } from '../../interfaces/table.interface'
import { GroupTypes } from '../../interfaces/group.interface'
import moment from 'moment'
import { RiEdit2Fill } from 'react-icons/ri'
import ConfirmationDeleteModal from '../Modal/ConfirmationDeleteModal'
import useDeleteGroup from '../../api/groups/useDeleteGroup'
import { authContext } from '../../context/auth-context'
import { CLIENTS } from '../../constant/routes'

interface Props extends PaginationTableTypes {
  groups: GroupTypes[]
  isModalTable?: boolean
}

const GroupsTable = ({
  groups = [],
  isModalTable = false,
  onSearchHandler,
  nextPage,
  previousPage,
  page,
  count,
  searchTerm,
  isFetching,
}: Props) => {
  const { isSelectedGroup, onSelectGroup } = useSelectClientsGroups()

  const { token } = useContext(authContext)
  const { mutate: deleteGroup, isLoading } = useDeleteGroup()

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value }: { value: string }) => {
          return <StyledSpan>{value || '-'}</StyledSpan>
        },
      },
      {
        Header: 'Number of Customers',
        accessor: 'number_of_customers',
      },
      {
        Header: 'Created Date',
        accessor: 'created_date',
        Cell: ({ value }: { value: string }) => {
          return moment(value).format('DD.MM.YYYY - HH:mm')
        },
      },
      {
        Header: 'Updated Date',
        accessor: 'modified_date',
        Cell: ({ value }: { value: string }) => {
          return moment(value).format('DD.MM.YYYY - HH:mm')
        },
      },
      {
        Header: 'Actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: GroupTypes } }
        }) => {
          return isModalTable ? (
            <TableAddRemoveActions isAdded={isSelectedGroup(original)} onClick={() => onSelectGroup(original)} />
          ) : (
            <StyledActionsWrapper>
              <Link to={`${CLIENTS.editGroup}/${original.id}`}>
                <RiEdit2Fill className="edit-icon" />
              </Link>
              <ConfirmationDeleteModal
                isLoading={isLoading}
                title="Delete Group"
                description="Are you sure you want to delete this group?"
                onConfirm={() => deleteGroup({ token: token!, id: original.id })}
              />
            </StyledActionsWrapper>
          )
        },
      },
    ],
    [deleteGroup, isLoading, isModalTable, isSelectedGroup, onSelectGroup, token],
  )

  return (
    <StyledTableContainer>
      <TableItems
        data={groups}
        columns={columns}
        text="Group"
        count={count}
        onSearchHandler={onSearchHandler}
        nextPage={nextPage}
        previousPage={previousPage}
        page={page}
        searchTerm={searchTerm}
        isFetching={isFetching}
        isPaginated
      />
    </StyledTableContainer>
  )
}

export default GroupsTable
