import { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import Layout from './components/Layout/Layout'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import News from './pages/News/News'
import EditArticle from './pages/News/EditArticle/EditArticle'
import AddArticle from './pages/News/AddArticle/AddArticle'
import NotFound from './pages/NotFound'
import useFetch from './hooks/useFetch'
import { CLIENTS, CROSS_SELLING, DASHBOARD, LOGIN, MESSAGES, NEWS, ORDERS, PRODUCTS } from './constant/routes'
import { NewsDataTypes } from './interfaces/news.interface'
import Products from './pages/Products/Products'
import EditProduct from './pages/Products/EditProduct/EditProduct'
import { toast } from 'react-toastify'
import Orders from './pages/Orders/Orders'
import OrderDetails from './pages/Orders/OrderDetails'
import CrossSelling from './pages/CrossSelling/CrossSelling'
import Clients from './pages/Clients/Clients'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import AddGroup from './pages/Clients/Groups/AddGroup'
import Messages from './pages/Messages/Messages'
import AddMessage from './pages/Messages/AddMessage/AddMessage'
import ClientDetails from './pages/Clients/ClientDetails'
import EditGroup from './pages/Clients/Groups/EditGroup'

const App = () => {
  const [products, setProducts] = useState<[]>([])
  const [isProductsLoading, setIsProductsLoading] = useState<boolean>(false)

  const { data: articles, error: articlesError, isLoading: isArticlesLoading }: NewsDataTypes = useFetch('news/article')

  const searchProducts = async (value: string) => {
    setIsProductsLoading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/shop/product/search/?search=${value.toUpperCase() || null}`,
        {
          method: 'POST',
          body: JSON.stringify({ default_filtering_type: 'all' }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      const data = await response.json()

      setProducts(data.results)
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message)
      }
    }
    setIsProductsLoading(false)
  }

  return (
    <>
      <Route path={LOGIN} exact>
        <Login />
      </Route>
      <Layout>
        <Switch>
          <Route path={DASHBOARD} exact>
            <Dashboard articles={articles} isArticlesLoading={isArticlesLoading} articlesError={articlesError} />
          </Route>

          <Route path={CLIENTS.main} exact>
            <Clients />
          </Route>

          <Route path={`${CLIENTS.details}/:id`} exact>
            <ClientDetails />
          </Route>

          <Route path={CLIENTS.addGroup} exact>
            <AddGroup />
          </Route>

          <Route path={`${CLIENTS.editGroup}/:id`} exact>
            <EditGroup />
          </Route>

          <Route path={MESSAGES.main} exact>
            <Messages />
          </Route>
          <Route path={MESSAGES.add} exact>
            <AddMessage />
          </Route>

          <Route path={NEWS.main} exact>
            <News articles={articles} isLoading={isArticlesLoading} articleError={articlesError} />
          </Route>
          <Route path={NEWS.add} exact>
            <AddArticle />
          </Route>
          <Route path={`${NEWS.edit}/:id`} exact>
            <EditArticle articles={articles} />
          </Route>
          <Route path={ORDERS.main} exact>
            <Orders />
          </Route>
          <Route path={`${ORDERS.show}/:id`} exact>
            <OrderDetails />
          </Route>
          <Route path={`${PRODUCTS.main}`} exact>
            <Products searchProducts={searchProducts} products={products} isProductsLoading={isProductsLoading} />
          </Route>
          <Route path={`${PRODUCTS.edit}/:id`} exact>
            <EditProduct products={products} searchProducts={searchProducts} />
          </Route>
          <Route path={CROSS_SELLING.main} exact>
            <CrossSelling />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Layout>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
    </>
  )
}

export default App
