export const dateFormat = (date: string) => {
  const d = new Date(date)
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)

  return `${day}.${month}.${year}`
}

export const editDateFormat = (date: string) => {
  const newDateFormat = date.split('.')
  const day = newDateFormat.shift()
  const year = newDateFormat.pop()

  return `${year}-${newDateFormat[0]}-${day}`
}
