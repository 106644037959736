import moment from 'moment'
import styled from 'styled-components'

interface Props {
  code: string
  date: string
  price: string
}

const DashboardOrdersItems = ({ code, date, price }: Props) => {
  return (
    <StyledOrderContainer>
      <StyledText>{code}</StyledText>
      <StyledText>{moment(date).format('DD.MM.YYYY - HH:mm')}</StyledText>
      <StyledText>{price}€</StyledText>
    </StyledOrderContainer>
  )
}

export default DashboardOrdersItems

const StyledOrderContainer = styled.div`
  width: 100%;
  padding: 21px 0;
  border-bottom: 1px solid var(--border-light-gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StyledText = styled.p`
  font-size: 18px;
  color: var(--primary-black);

  &:nth-child(1) {
    width: 130px;
  }
  &:nth-child(3) {
    width: 90px;
    text-align: right;
  }
`
