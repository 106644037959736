import React, { useContext } from 'react'
import { StyledSpan, StyledTableContainer } from '../Table/styles'
import TableItems from '../Table/TableItems/TableItems'
import { CustomerTypes } from '../../interfaces/clients.interface'
import TableAddRemoveActions from '../Table/TableAddRemoveActions'
import { useSelectClientsGroups } from '../../context/select-clients-groups-context'
import { MdOutlineInfo } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { CLIENTS } from '../../constant/routes'
import { PaginationTableTypes } from '../../interfaces/table.interface'
import useUpdateGroupTemp from '../../api/groups/useUpdateGroupTemp'
import { authContext } from '../../context/auth-context'
import Spinner from '../UI/Spinners/Spinner'

interface Props extends PaginationTableTypes {
  customers: CustomerTypes[]
  isModalTable?: boolean
  isPaginated?: boolean
  isPortalSearch?: boolean
}

const ClientsTable = ({
  customers = [],
  isModalTable = false,
  onSearchHandler,
  nextPage,
  previousPage,
  page,
  count,
  searchTerm,
  isFetching,
  isPaginated = true,
  isPortalSearch = false,
}: Props) => {
  const { isSelectedClient, onSelectClient, tempId } = useSelectClientsGroups()
  const history = useHistory()
  const { token } = useContext(authContext)
  const { mutate: updateTemp, isLoading } = useUpdateGroupTemp()

  const columns = [
    {
      Header: 'ID',
      accessor: 'customer_info.cus_id',
      Cell: ({ value }: { value: string }) => {
        return <StyledSpan>{value ?? '-'}</StyledSpan>
      },
    },
    {
      Header: 'Code',
      accessor: 'customer_info.code',
      Cell: ({ value }: { value: string }) => {
        return <StyledSpan>{value ?? '-'}</StyledSpan>
      },
    },
    {
      Header: 'Name',
      accessor: 'customer_info.name',
      Cell: ({ value }: { value: string }) => {
        return <StyledSpan>{value ?? '-'}</StyledSpan>
      },
    },
    {
      Header: 'AFM',
      accessor: 'customer_info.afm',
      Cell: ({ value }: { value: string }) => {
        return <StyledSpan>{value ?? '-'}</StyledSpan>
      },
    },
    {
      Header: 'Email',
      accessor: 'customer_info.email',
      Cell: ({ value }: { value: string }) => {
        return <a href={`mailto:${value}`}>{value}</a>
      },
    },
    {
      Header: 'Actions',
      Cell: ({
        cell: {
          row: { original },
        },
      }: {
        cell: { row: { original: CustomerTypes } }
      }) => {
        const customerId = original.customer_info?.cus_id

        if (isModalTable) {
          return isLoading ? (
            <Spinner size="small" />
          ) : (
            <TableAddRemoveActions
              isAdded={isSelectedClient(original)}
              onClick={() => {
                if (tempId) {
                  updateTemp(
                    {
                      token: token!,
                      id: tempId,
                      data: {
                        operation: isSelectedClient(original) ? 'remove' : 'add',
                        customer_ids: [customerId],
                      },
                    },
                    {
                      onSuccess: () => {
                        onSelectClient(original)
                      },
                    },
                  )
                  return
                }
                onSelectClient(original)
              }}
            />
          )
        }

        return (
          <MdOutlineInfo
            size={24}
            onClick={customerId ? () => history.push(`${CLIENTS.details}/${customerId}`) : undefined}
            cursor={customerId ? 'pointer' : 'not-allowed'}
            color={customerId ? '#000' : '#C0C0C0'}
            title={customerId ? 'Client Details' : 'Not Available'}
          />
        )
      },
    },
  ]

  return (
    <StyledTableContainer>
      <TableItems
        data={customers}
        columns={columns}
        text="Client"
        count={count}
        onSearchHandler={onSearchHandler}
        nextPage={nextPage}
        previousPage={previousPage}
        page={page}
        searchTerm={searchTerm}
        isFetching={isFetching}
        isPaginated={isPaginated}
        isPortalSearch={isPortalSearch}
      />
    </StyledTableContainer>
  )
}

export default ClientsTable
