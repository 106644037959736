import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import styled from 'styled-components'
import { MdDelete } from 'react-icons/md'
import { RiEdit2Fill } from 'react-icons/ri'
import { authContext } from '../../../context/auth-context'
import { deleteResourceById } from '../../../helpers/deleteResourceById'
import { NEWS } from '../../../constant/routes'

interface Props {
  title: string
  date: string
  id: number
}

const DashboardNewsItem = ({ title, date, id }: Props) => {
  const { token, destroyToken } = useContext(authContext)
  const { mutate } = useSWRConfig()
  const urlDelete = `news/article/${id}/`

  return (
    <StyledItemContainer>
      <StyledText>{title}</StyledText>
      <StyledText>{date}</StyledText>
      <StyledActionsWrapper>
        <Link to={`${NEWS.edit}/${id}`}>
          <RiEdit2Fill className='edit-icon' />
        </Link>
        <MdDelete
          onClick={() => deleteResourceById({ url: urlDelete, token, mutate, destroyToken })}
          className='delete-icon'
        />
      </StyledActionsWrapper>
    </StyledItemContainer>
  )
}

export default DashboardNewsItem

const StyledItemContainer = styled.div`
  width: 100%;
  padding: 21px 0;
  border-bottom: 1px solid var(--border-light-gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StyledText = styled.p`
  font-size: 18px;
  color: var(--primary-black);
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 10px;
`
const StyledActionsWrapper = styled.div`
  display: flex;
  gap: 18px;

  .edit-icon {
    color: var(--primary-black);
    transform: scale(1.2);
  }
  .delete-icon {
    color: var(--delete);
    cursor: pointer;
    transform: scale(1.2);
  }
`
