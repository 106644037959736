// /api/groups/user-group/7/update-temp/?page=1

import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { authContext } from '../../context/auth-context'
import { Paginated } from '../../interfaces/paginated.interface'
import { CustomerTypes } from '../../interfaces/clients.interface'
import { useSelectClientsGroups } from '../../context/select-clients-groups-context'

const viewTemp = async ({
  token,
  id,
  searchTerm,
  page,
}: {
  id: number
  token: string
  page: number
  searchTerm: string
}) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/groups/user-group/${id}/update-temp/?search=${searchTerm}&page=${page}&page_size=5`,
    {
      method: 'POST',
      body: JSON.stringify({
        operation: 'view',
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )

  return response.json()
}

const useGroupViewTemp = ({ page = 1, searchTerm = '' }: { page: number; searchTerm: string }) => {
  const { token } = useContext(authContext)
  const { tempId } = useSelectClientsGroups()

  const { data, isLoading, error } = useQuery({
    queryKey: ['group-view-temp', tempId, page, searchTerm],
    queryFn: () => viewTemp({ token: token!, id: tempId!, searchTerm, page }),
    cacheTime: 0,
    enabled: !!tempId,
    keepPreviousData: true,
  })

  return { data: data as Paginated<CustomerTypes[]>, isLoading, error }
}

export default useGroupViewTemp
