import { StyledContainer } from '../../../components/UI/StyledContainer'
import { StyledPageTitle } from '../../../components/UI/StyledPageTitle'
import GroupForm from '../../../components/GroupForm/GroupForm'
import { useSelectClientsGroups } from '../../../context/select-clients-groups-context'
import { GroupFormTypes } from '../../../interfaces/group.interface'
import { toast } from 'react-toastify'
import { useContext, useEffect } from 'react'
import { authContext } from '../../../context/auth-context'
import useCreateGroup from '../../../api/groups/useCreateGroup'
import { useHistory } from 'react-router-dom'

const AddGroup = () => {
  const { selectedClients, resetSelectedClients } = useSelectClientsGroups()
  const { token } = useContext(authContext)
  const history = useHistory()
  const { mutate, isLoading } = useCreateGroup()

  const onSubmit = (values: GroupFormTypes) => {
    if (selectedClients.length === 0) {
      toast.error('Clients are required')
      return
    }
    mutate(
      {
        token: token!,
        data: {
          name: values.name,
          description: values.description,
          customers: selectedClients.map(c => c.customer_info.cus_id),
        },
      },
      {
        onSuccess: () => {
          resetSelectedClients()
          history.goBack()
        },
      },
    )
  }

  useEffect(() => () => resetSelectedClients(), [resetSelectedClients])

  return (
    <StyledContainer formContent>
      <StyledPageTitle>Create Group</StyledPageTitle>
      <GroupForm onSubmit={onSubmit} isFormLoading={isLoading} />
    </StyledContainer>
  )
}

export default AddGroup
