interface Props {
  className?: string
}

const IconUsers = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      className={className}>
      <path
        d="M23.9061 24.307H22.2431V20.145C22.2417 19.0416 21.8028 17.9837 21.0226 17.2035C20.2423 16.4232 19.1845 15.9843 18.0811 15.983V14.318C19.6256 14.3201 21.1063 14.9348 22.1983 16.0271C23.2903 17.1195 23.9045 18.6004 23.9061 20.145V24.307Z"
        fill="black"
      />
      <path
        d="M15.155 1.71198V3.38398C16.2636 3.38398 17.3268 3.82437 18.1107 4.60827C18.8946 5.39217 19.335 6.45537 19.335 7.56398C19.335 8.67258 18.8946 9.73578 18.1107 10.5197C17.3268 11.3036 16.2636 11.744 15.155 11.744V13.416C15.9361 13.4364 16.7134 13.3002 17.441 13.0154C18.1687 12.7306 18.8319 12.3029 19.3916 11.7577C19.9512 11.2125 20.3961 10.5607 20.6998 9.84073C21.0035 9.12081 21.16 8.34735 21.16 7.56598C21.16 6.7846 21.0035 6.01114 20.6998 5.29122C20.3961 4.5713 19.9512 3.91949 19.3916 3.37425C18.8319 2.829 18.1687 2.40137 17.441 2.11656C16.7134 1.83176 15.9361 1.69555 15.155 1.71598V1.71198Z"
        fill="black"
      />
      <path
        d="M0 24.307H16.928V20.075C16.9264 18.504 16.3017 16.9979 15.1909 15.8871C14.08 14.7763 12.5739 14.1516 11.003 14.15H5.925C4.35408 14.1516 2.84796 14.7763 1.73715 15.8871C0.626338 16.9979 0.00158839 18.504 0 20.075V24.307Z"
        fill="black"
      />
      <path
        d="M2.53906 6.53297C2.53906 7.70483 2.88656 8.85036 3.53761 9.82473C4.18865 10.7991 5.11401 11.5585 6.19666 12.007C7.27931 12.4554 8.47063 12.5727 9.61997 12.3441C10.7693 12.1155 11.825 11.5512 12.6537 10.7226C13.4823 9.89395 14.0466 8.83822 14.2752 7.68888C14.5038 6.53954 14.3865 5.34822 13.938 4.26557C13.4896 3.18292 12.7302 2.25756 11.7558 1.60651C10.7815 0.955466 9.63592 0.607971 8.46406 0.607971C6.89265 0.607971 5.38561 1.23221 4.27446 2.34336C3.1633 3.45452 2.53906 4.96156 2.53906 6.53297Z"
        fill="black"
      />
    </svg>
  )
}

export default IconUsers
