import { createContext, useState } from 'react'
interface AuthContextTypes {
  token: string | null
  saveToken: (value: string) => void
  destroyToken: () => void
}

interface Children {
  children: React.ReactNode
}

export const authContext = createContext<AuthContextTypes>({
  token: null,
  saveToken: () => null,
  destroyToken: () => null,
})

const AuthContextProvider = ({ children }: Children) => {
  const getToken = () => {
    const tokenString: any = localStorage.getItem('token')
    const userToken = JSON.parse(tokenString)
    return userToken
  }
  const [token, setToken] = useState<AuthContextTypes['token']>(getToken())

  const saveToken = (userToken: string) => {
    localStorage.setItem('token', JSON.stringify(userToken))
    setToken(userToken)
  }

  const destroyToken = () => {
    localStorage.removeItem('token')
    setToken(null)
  }

  const ctx = {
    token,
    saveToken,
    destroyToken,
  }

  return <authContext.Provider value={ctx}>{children}</authContext.Provider>
}

export default AuthContextProvider
