import { useEffect } from 'react'
import LoginForm from '../components/LoginForm/LoginForm'

const Login = () => {
  useEffect(() => {
    document.title = 'Babywalker | Login'
  }, [])

  return <LoginForm />
}

export default Login
