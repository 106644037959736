import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { useSelectClientsGroups } from '../../context/select-clients-groups-context'

const updateTemp = async ({
  token,
  id,
  data,
}: {
  token: string
  id: number
  data: {
    operation: 'add' | 'remove'
    customer_ids: number[]
  }
}) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/groups/user-group/${id}/update-temp/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return response.json()
}

const useUpdateGroupTemp = () => {
  const queryClient = useQueryClient()
  const { tempId } = useSelectClientsGroups()

  const { mutate, isLoading } = useMutation({
    mutationFn: updateTemp,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['group-view-temp', tempId])
    },
    onError: () => {
      toast.error('Failed to update group')
    },
  })

  return { mutate, isLoading }
}

export default useUpdateGroupTemp
